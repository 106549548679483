
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, List, Checkbox } from 'semantic-ui-react'
import CommonFunctionsUtils from '../CommonFunctionsUtils'
import CommunicationUtils from '../CommunicationUtils'
import SettingsUtils from '../SettingsUtils'

let $ = window.$
class ContactInfoForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  
    constructor(props) {
        super(props);


        let additionalEmailAddresses = {}
        if(this.props.entity.additionalEmailAddresses !== undefined){
            additionalEmailAddresses = JSON.parse(JSON.stringify(this.props.entity.additionalEmailAddresses))
        }

        let hideAdditionalEmailAddresses = false
        if(this.props.hideAdditionalEmailAddresses !== undefined){
            hideAdditionalEmailAddresses = this.props.hideAdditionalEmailAddresses
        }

        // let showAdditionalEmailAddresses = false
        // if(this.props.entity.additionalEmailAddresses !== undefined && Object.keys(this.props.entity.additionalEmailAddresses).length > 0){
        //     showAdditionalEmailAddresses = true
        // }

        let emailNotificationOptIn = SettingsUtils.getEmailNotificationOptIn(this.props.company, this.props.entity)
        let textNotificationOptIn = SettingsUtils.getTextNotificationOptIn(this.props.company, this.props.entity)

        this.state = { 
            entity: this.props.entity,
            additionalEmailAddresses,
            showAdditionalEmailAddresses: false,
            additionalEmailValid: false,
            additionalEmailNotify: true,
            hideAdditionalEmailAddresses: hideAdditionalEmailAddresses,
            emailNotificationOptIn,
            textNotificationOptIn:false,
        };

        
        this.updateAdditionalEmailNotifyCheckbox = this.updateAdditionalEmailNotifyCheckbox.bind(this)
        this.updateRenderedAdditionalEmailNotifyCheckbox = this.updateRenderedAdditionalEmailNotifyCheckbox.bind(this)
    }

    componentDidMount(){

        $(".phoneFields").keyup(function () {
            if (this.value.length == this.maxLength) {
              $(this).next('.phoneFields').focus();
            }
        });

        this.infoChanged()
    }

    updateAdditionalEmailNotifyCheckbox(e, value){
        let additionalEmailNotify = value.checked
        this.setState({additionalEmailNotify})
    }

    updateRenderedAdditionalEmailNotifyCheckbox(e, value){
        let additionalEmailNotify = value.checked
        let additionalEmailAddresses = JSON.parse(JSON.stringify(this.state.additionalEmailAddresses))
        let additonalEmailAddress = additionalEmailAddresses[value.id]
        additonalEmailAddress.notify = additionalEmailNotify
        this.setState({additionalEmailAddresses})
    }

    phoneNumberChanged(){
        let phoneField = this.refs.phoneNumber.value
        phoneField = phoneField.replace(/[^0-9]/g, "");
        this.refs.phoneNumber.value = phoneField
        this.infoChanged()
    }

    phoneField1Changed(){
        let phoneField = this.refs.areaCode.value
        phoneField = phoneField.replace(/[^0-9]/g, "");
        this.refs.areaCode.value = phoneField
        this.infoChanged()
    }

    phoneField2Changed(){
        let phoneField = this.refs.number1.value
        phoneField = phoneField.replace(/[^0-9]/g, "");
        this.refs.number1.value = phoneField
        this.infoChanged()
    }

    phoneField3Changed(){
        let phoneField = this.refs.number2.value
        phoneField = phoneField.replace(/[^0-9]/g, "");
        this.refs.number2.value = phoneField
        this.infoChanged()
    }

    infoChanged(){
        let valid = this.validateForm()

        let email = this.refs.email.value
        if(email.trim() === ''){
            email = undefined
        }

        let additionalEmailAddresses = undefined
        if(this.state.additionalEmailAddresses !== undefined && Object.keys(this.state.additionalEmailAddresses).length > 0){
            additionalEmailAddresses = JSON.parse(JSON.stringify(this.state.additionalEmailAddresses))
        }

        let phone = undefined
        if(!this.props.emailOnly){
            let phoneNumber = this.refs.phoneNumber.value
            phoneNumber = phoneNumber.replace(/[^0-9]/g, "");
            let areaCode = phoneNumber.substring(0, 3)
            let number = `${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6, 10)}`
            phone = {areaCode, number}
            if(phone.areaCode === '' && phone.number === '-'){
                phone = undefined
            }
        }

        let additionalPhoneNumbers = undefined

        if(this.props.contactInfoChanged !== undefined){
            let params = {
                valid,
                email,
                additionalEmailAddresses,
                emailNotificationOptIn: this.state.emailNotificationOptIn,
                phone,
                additionalPhoneNumbers,
                textNotificationOptIn: this.state.textNotificationOptIn,
            }

            this.props.contactInfoChanged(params)
        }
    }

    validateForm(){
        let valid = false

        let phoneValid = false
        let phoneLength = 0
        if(this.refs.phoneNumber !== undefined && this.refs.phoneNumber !== null){
            let phoneNumber = this.refs.phoneNumber.value
            phoneNumber = phoneNumber.replace(/[^0-9]/g, "");
            phoneLength = phoneNumber.length
            if(phoneLength >= 10){
                phoneValid = true
            }
        }

        let emailValid = false
        let email = ''
        if(this.refs.email !== undefined && this.refs.email !== null){
            email = this.refs.email.value
            if(email !== undefined){
                email = email.trim()
            }
            emailValid = CommonFunctionsUtils.validateEmail(email)
        }

        if(phoneValid && this.props.phoneOnly){
            return true
        }else if(emailValid && this.props.emailOnly){
            return true
        }else if(phoneValid && this.props.oneIsRequired){
            if(!emailValid && email.length !== 0){
                valid = false
            }else{
                valid = true
            }
        }else if(emailValid && (this.props.oneIsRequired || this.props.emailValid)){
            if(!phoneValid && phoneLength > 0){
                valid = false
            }else{
                valid = true
            }
        }else if(phoneValid && emailValid){
            valid = true
        }

        return valid
    }

    getPhoneNumber(){
        let phoneNumber = this.getPhoneAreaCodeValue() + this.getPhoneNumber1Value() + this.getPhoneNumber2Value()
        if(phoneNumber === undefined || phoneNumber === ''){
          return phoneNumber
        }else{
            if(phoneNumber.length > 0){
                let strippedPhoneNumber = phoneNumber.replace(/[^0-9/.]/g, "");
                if(strippedPhoneNumber.length > 0){
                    strippedPhoneNumber = '(' + strippedPhoneNumber
                }
                if(strippedPhoneNumber.length > 4){
                    strippedPhoneNumber = strippedPhoneNumber.slice(0, 4) + ')' + strippedPhoneNumber.slice(4);
                }
                if(strippedPhoneNumber.length > 8){
                    strippedPhoneNumber = strippedPhoneNumber.slice(0, 8) + '-' + strippedPhoneNumber.slice(8);
                }
                if(strippedPhoneNumber.length >= 13){
                    strippedPhoneNumber = strippedPhoneNumber.slice(0, 13);
                }
                return strippedPhoneNumber
            }
        }
    }
    
    getEmailValue(){
        if(this.props.entity !== undefined && this.props.entity.email !== undefined){
            return this.props.entity.email
        }else{
            return ''
        }
    }

    getPhoneAreaCodeValue(){
        if(this.props.entity !== undefined && this.props.entity.phone !== undefined && this.props.entity.phone.areaCode !== undefined){
            return this.props.entity.phone.areaCode
        }else{
            return ''
        }
    }

    getPhoneNumber1Value(){
        if(this.props.entity !== undefined && this.props.entity.phone !== undefined && this.props.entity.phone.number !== undefined){
            let number = this.props.entity.phone.number.trim().split("-");
            return number[0]
        }else{
            return ''
        }
    }

    getPhoneNumber2Value(){
        if(this.props.entity !== undefined && this.props.entity.phone !== undefined && this.props.entity.phone.number !== undefined){
            let number = this.props.entity.phone.number.trim().split("-");
            return number[1]
        }else{
            return ''
        }
    }

  render() {
    let phoneLabel = <label>Phone</label>
    let emailLabel = <label>Email</label>

    if(!this.props.showLabels){
        phoneLabel = undefined
        emailLabel = undefined
    }

    let phoneNumber = this.getPhoneNumber()
    let isPhoneValid = CommonFunctionsUtils.isPhoneValid(CommunicationUtils.convertToPhoneNumber(phoneNumber))

    let phone = 
        <div>
            <div className="field">
                {phoneLabel}
                <div className="field">
                    <div style={{display: 'flex'}}>
                        <input 
                            ref='phoneNumber' 
                            type="text" 
                            name="phone" 
                            placeholder="(123)456-7890" 
                            maxLength="13"
                            autoComplete="nope" 
                            value={this.getPhoneNumber()}
                            onChange={() => {this.phoneNumberChanged()}}
                        />
                    </div>
                </div>
            </div>
                <div className="field">
                    <label>
                        <label>Text Opt In</label>
                        <div style={{ display: 'flex' }}>
                            <br />
                                {<Checkbox
                                style={{ marginTop: '.2em'}}
                                    ref='phoneCheckBox'
                                    name='phoneCheckBox'
                                    onChange={(e, checkbox) => {
                                        let _this = this
                                        this.setState({textNotificationOptIn: checkbox.checked})
                                        setTimeout(function(){ 
                                            _this.infoChanged()
                                        }, 500);
                                    }}
                                />}
                            <div style={{ marginLeft: '.5em', fontWeight: '300' }}>{`By checking this box, you give consent to ${this.props.company.properties.businessName} to send SMS messages. Message frequency may vary. Message and data rates may apply. Reply STOP to unsubscribe and HELP for assistance.`}</div>
                        </div>
                    </label>
                </div>
        </div>

    if(this.props.emailOnly){
        phone = undefined
    }

    let email =
        <div className="field">
            {emailLabel}
            <input ref='email' type="text" name="email" placeholder="Email" 
                    spellCheck="false"
                    value={this.getEmailValue()} onChange={() => {this.infoChanged()}}/>
        </div>

    if(this.props.phoneOnly){
        email = undefined
    }

    return (
        <div className="contactInfoForm" style={{fontSize: this.props.fontSize, margin:' 1em 0em'}}>
            {email}
            {phone}
        </div>
    );
  }
 
}

ContactInfoForm.propTypes = {
    entity: PropTypes.object,
    emailOnly: PropTypes.bool,
    contactInfoChanged: PropTypes.func,
    fontSize: PropTypes.number,
    showLabels: PropTypes.bool,
    oneIsRequired: PropTypes.bool,
}

export default ContactInfoForm
