import { DateTime } from "luxon";

class TimeUtils {

    useBusinessHours(company){
        if(company !== undefined && company.businessHoursProperties !== undefined &&
            company.businessHoursProperties.useBusinessHours !== undefined){
            return company.businessHoursProperties.useBusinessHours
        }else{
            return false
        }
    }

    getBusinessHours(company){
        if(company !== undefined && company.businessHoursProperties !== undefined){
            return company.businessHoursProperties
        }else{
            return undefined
        }
    }

    filterBusinessHoursDate(company, date){
        if(this.useBusinessHours(company)){
            let businessHours = this.getBusinessHours(company)
            if(businessHours !== undefined){
                let day = date.getDay()
                let dayOfWeek = this.getDayOfWeek(day)
                let dayData = businessHours[dayOfWeek]
                if(dayData !== undefined && dayData.isOpen){
                    return true
                }else{
                    return false
                }
            }else{
                return false
            }
        }else{
            return true
        }
    }

    filterBusinessHoursTime(company, time){
        if(this.useBusinessHours(company)){
            let businessHours = this.getBusinessHours(company)
            if(businessHours !== undefined){
                let day = time.getDay()
                let dayOfWeek = this.getDayOfWeek(day)
                let dayData = businessHours[dayOfWeek]
                if(dayData !== undefined && dayData.isOpen){
                    let t = DateTime.fromMillis(time.getTime())
                    let openTime = DateTime.fromMillis(dayData.openTime)
                    let closeTime = DateTime.fromMillis(dayData.closeTime)
                    if(t.hour >= openTime.hour && t.hour <= closeTime.hour){
                        if(t.hour === openTime.hour){
                            if(t.minute >= openTime.minute){
                                return true
                            }else{
                                return false
                            }
                        }else if(t.hour === closeTime.hour){
                            if(t.minute <= closeTime.minute){
                                return true
                            }else{
                                return false
                            }
                        }else{
                            return true
                        }
                    }else{
                        return false
                    }
                }else{
                    return false
                }
            }else{
                return false
            }
        }else{
            return true
        }
    }

    getDayOfWeek(day){
        if(day === 0){
          return 'Sunday'
        }else if(day === 1){
          return 'Monday'
        }else if(day === 2){
          return 'Tuesday'
        }else if(day === 3){
          return 'Wednesday'
        }else if(day === 4){
          return 'Thursday'
        }else if(day === 5){
          return 'Friday'
        }else if(day === 6){
          return 'Saturday'
        }
    }
}

export default new TimeUtils()