/**
 *
 * Forms
 *
 */

import React from 'react';
import axios from 'axios'
import './App.css';
import FireStoreParser from 'firestore-parser'
import NameForm from './NameForm'
import ContactInfoForm from './ContactInfoForm'
import AddressForm from './AddressForm'
import SelectableText from './SelectableText'
import TimeUtils from './TimeUtils'
import SettingsUtils from './SettingsUtils'
import CommonFunctionsUtils from './CommonFunctionsUtils'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Message, Button, Dropdown, Image, Header } from 'semantic-ui-react'
import Dropzone from 'react-dropzone';
import EditableAttachments from './EditableAttachments';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { storage, firestore, firebase } from './firebaseConfig';
import ReactLoading from 'react-loading';
import FadeIn from "react-fade-in";
import SignatureCanvas from 'react-signature-canvas'
import LegalModal from './LegalModal'
// window.jQuery = window.$ = $;
let $ = window.$
const host = window.location.hostname === 'forms.yourdocket.com' ? 'docket-3257f' : 'docket-dev-237ce'
// const host = 'docket-3257f'
// const host = 'docket-dev-237ce'
let api = `https://firestore.googleapis.com/v1beta1/projects/${host}/databases/(default)/documents`
export class App extends React.PureComponent {

  constructor(props) {
    super(props);

    let form = {
      html: ''
    }

    this.state = {
      contactInfoValid: true,
      showDropDowns: true,
      embed:false,
      date: moment(),
      form: form,
      showForm: true,
      name: 'test',
      textHtml: '',
      elements: [],
      invalidReasons: [],
      files: [],
      attachments: [],
      lead: {
        name: {
          first: '',
          last: ''
        },
        phone: {
          areaCode: '',
          number: '',
        },
        email: '',
        preferences: {
          emailNotificationOptIn: true,
          textNotificationOptIn: false,
        }
      },

      company: {
        properties: {
          businessName: "",
          image: ""
        },
        businessHoursProperties: undefined,
      },
      dumpsterSizes: [
        { key: 1, text: '1 Yard', value: 1 },
        { key: 2, text: '2 Yard', value: 2 },
        { key: 3, text: '3 Yard', value: 3 },
        { key: 4, text: '4 Yard', value: 4 },
        { key: 5, text: '5 Yard', value: 5 },
        { key: 6, text: '6 Yard', value: 6 },
        { key: 7, text: '7 Yard', value: 7 },
        { key: 8, text: '8 Yard', value: 8 },
        { key: 9, text: '9 Yard', value: 9 },
        { key: 10, text: '10 Yard', value: 10 },
        { key: 11, text: '11 Yard', value: 11 },
        { key: 12, text: '12 Yard', value: 12 },
        { key: 13, text: '13 Yard', value: 13 },
        { key: 14, text: '14 Yard', value: 14 },
        { key: 15, text: '15 Yard', value: 15 },
        { key: 16, text: '16 Yard', value: 16 },
        { key: 17, text: '17 Yard', value: 17 },
        { key: 18, text: '18 Yard', value: 18 },
        { key: 19, text: '19 Yard', value: 19 },
        { key: 20, text: '20 Yard', value: 20 },
        { key: 21, text: '21 Yard', value: 21 },
        { key: 22, text: '22 Yard', value: 22 },
        { key: 23, text: '23 Yard', value: 23 },
        { key: 24, text: '24 Yard', value: 24 },
        { key: 25, text: '25 Yard', value: 25 },
        { key: 30, text: '30 Yard', value: 30 },
        { key: 35, text: '35 Yard', value: 35 },
        { key: 40, text: '40 Yard', value: 40 },
        { key: 50, text: '50 Yard', value: 50 },
        { key: 60, text: '60 Yard', value: 60 },
        { key: 80, text: '80 Yard', value: 80 },
      ],
      dumpsterRentalLengths: [
        { key: 0, text: '1 Hour', value: 'hourlyRate' },
        { key: 1, text: '1 Day', value: 'dailyRate' },
        { key: 2, text: '2 Day', value: 'twoDayRate' },
        { key: 3, text: '3 Day', value: 'threeDayRate' },
        { key: 4, text: '4 Day', value: 'fourDayRate' },
        { key: 5, text: '5 Day', value: 'fiveDayRate' },
        { key: 6, text: '6 Day', value: 'sixDayRate' },
        { key: 7, text: '7 Day', value: 'weeklyRate' },
        { key: 8, text: '8 Day', value: 'eightDayRate' },
        { key: 9, text: '9 Day', value: 'nineDayRate' },
        { key: 10, text: '10 Day', value: 'tenDayRate' },
        { key: 11, text: '11 Day', value: 'elevenDayRate' },
        { key: 12, text: '12 Day', value: 'twelveDayRate' }, 
        { key: 13, text: '13 Day', value: 'thirteenDayRate' },   
        { key: 14, text: '14 Day', value: 'twoWeekRate' },
        { key: 15, text: '15 Day', value: 'fifteenDayRate' },
        { key: 21, text: '21 Day', value: 'twentyOneDayRate' },
        { key: 28, text: '28 Day', value: 'twentyEightDayRate' },
        { key: 30, text: '30 Day', value: 'monthlyRate' },
        { key: 60, text: '60 Day', value: 'sixtyDayRate' },
      ],
      
      lightboxImages: [],
      lightboxPhotoIndex: 0,
      lightBoxIsOpen: false,
    }

    this.requiredElements = {}
    this.lastDumpsterRentalLength = moment().valueOf()
    this.lastDumpsterSize = moment().valueOf()
  }

  componentDidMount() {
    function getParameterByName(name) {
      var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
{/* <iframe src="https://forms.yourdocket.com/?form=WrzNDaObCoUOCXGd926e&embed=true&showHeader=false" width="100%" height="1420" scrolling="yes" style="border:1px solid #DDD;"></iframe> */}
    let _this = this
    let id = getParameterByName('form')
    let embed = getParameterByName('embed')
    let fid = getParameterByName('fid')
    let showHeader = true    
    if(getParameterByName('showHeader')==="false"){
      showHeader = false
    }
    this.setState({embed, fid, showHeader, formId: id})
    if(id === undefined || id === null) return ;
    fetch(`${api}/forms/${id}`)
      .then(response => response.json())
      .then(json =>{if(json.error !== undefined){throw "asd"}; return FireStoreParser(json.fields)})
      .then(data => {
        _this.setState({ data, textHtml: data.html, elements: data.elements, submitMessage: data.submitMessage })
        fetch(`${api}/public/${data.teamId}`)
          .then(response => response.json())
          .then(json => FireStoreParser(json.fields))
          .then(companyData => {
            _this.setState({ company: companyData })
            fetch(`${api}/public/${data.teamId}/dumpsters/templates`)
              .then(response => response.json())
              .then(json => FireStoreParser(json.fields))
              .then(templateData => {
                if(companyData){
                  let cdata = {...companyData}
                  if(templateData){
                    for(let key of Object.keys(templateData)){
                      templateData[key].category = 'Dumpster'
                    }
                  }
                  cdata.templates = templateData
                  _this.setState({ company: cdata })
                  _this.forceUpdate()
                }
              });
          });
      });


    // Removed because the semantic ui react dropdown does it for us.... this is weird
    // $('.ui.dropdown')
    //   .dropdown()
    //   ;


    $(".phoneFields").keyup(function () {
      if (this.value.length == this.maxLength) {
        $(this).next('.phoneFields').focus();
      }
    });

    this.addressChanged();
  }

  componentDidUpdate(){
    // Removed because the semantic ui react dropdown does it for us.... this is weird
    // $('.ui.dropdown')
    //   .dropdown()
    //   ;
  }

  getAddressLine1Value(address) {
    if (address !== undefined && address.addressLine1 !== undefined) {
      return address.addressLine1
    } else {
      return ''
    }
  }

  getAddressLine2Value() {
    if (this.state.address !== undefined && this.state.address.addressLine2 !== undefined) {
      return this.state.address.addressLine2
    } else {
      return ''
    }
  }

  getAddressCityValue(address) {
    if (address !== undefined && address.city !== undefined) {
      return address.city
    } else {
      return ''
    }
  }

  getAddressStateValue(address) {
    if (address !== undefined && address.state !== undefined) {
      return address.state
    } else {
      return ''
    }
  }

  getAddressCountyValue(address) {
    if (address !== undefined && address.county !== undefined) {
      return address.county
    } else {
      return ''
    }
  }

  getAddressCountyVerboseValue(address) {
    if (address !== undefined && address.countyVerbose !== undefined) {
      return address.countyVerbose
    } else {
      return ''
    }
  }

  getAddressZipValue(address) {
    if (address !== undefined && address.zip !== undefined) {
      return address.zip
    } else {
      return ''
    }
  }

  addressChanged(address) {
    let valid = this.validateForm()
    // this.props.addressChanged(valid, address)
  }

  validateForm() {
    let countryCode = SettingsUtils.getCountryCode(this.state.company)
    let postalCodeLabel = 'zip'
    if(countryCode === 'CA'){
      postalCodeLabel = 'postal'
    }

    $('.leadForm')
      .form({
        on: 'blur',
        fields: {
          firstName: {
            identifier: 'firstName',
            rules: [
              {
                type: 'empty',
                prompt: 'Please enter your first name'
              }
            ]
          },
          lastName: {
            identifier: 'lastName',
            rules: [
              {
                type: 'empty',
                prompt: 'Please enter last name'
              }
            ]
          },
          email: {
            identifier: 'email',
            rules: [
              {
                type: 'empty',
                prompt: 'Please enter your email'
              }
            ]
          },
          address1: {
            identifier: 'address1',
            rules: [
              {
                type: 'empty',
                prompt: 'Please enter your address'
              }
            ]
          },
          city: {
            identifier: 'city',
            rules: [
              {
                type: 'empty',
                prompt: 'Please enter your city'
              }
            ]
          },
          state: {
            identifier: 'state',
            rules: [
              {
                type: 'exactCount[1]',
                prompt: 'Please select your state'
              }
            ]
          },
          zip: {
            identifier: 'zip',
            rules: [
              {
                type: 'empty',
                prompt: `Please enter a ${postalCodeLabel} code`
              },
            ]
          },
          phoneNumber: {
            identifier: 'phone',
            rules: [
              {
                type: 'empty',
                prompt: 'Please enter your phone number'
              },
              {
                type: 'exactLength[13]',
              }
            ]
          },
        },
      })
      ;

    $('.leadForm').form('validate form')

    let valid = false
    if ($('.leadForm').form('is valid')) {
      valid = true
    }
    return valid
  }


  updateName(valid, name, businessName) {
    let lead = JSON.parse(JSON.stringify(this.state.lead))
    lead.name = name
    lead.businessName = businessName
    if (businessName != undefined && businessName !== '') {
      lead.isBusiness = true
    } else {
      lead.isBusiness = false
    }
    this.setState({ activeStepValid: valid, lead })
  }

  updateContactInfo(params) {
    if (!params) {
      params = {}
    }
    let { valid, email, additionalEmailAddresses, emailNotificationOptIn, phone, additionalPhoneNumbers, textNotificationOptIn } = params;

    let lead = JSON.parse(JSON.stringify(this.state.lead));
    lead.email = email
    lead.additionalEmailAddresses = additionalEmailAddresses
    lead.phone = phone
    if(!lead.preferences){
      lead.preferences = {}
    }
    lead.preferences.emailNotificationOptIn = emailNotificationOptIn
    lead.preferences.textNotificationOptIn = textNotificationOptIn

    this.setState({ contactInfoValid: valid, lead })
  }

  updateAddress(valid, address) {
    let lead = JSON.parse(JSON.stringify(this.state.lead));
    lead.address = address
    this.setState({ activeStepValid: valid, lead, address })
  }

  updateServiceAddress(valid, address) {
    let lead = JSON.parse(JSON.stringify(this.state.lead));
    lead.serviceAddress = address
    this.setState({ activeStepValid: valid, lead, serviceAddress:address })
  }

  getSelectValues(select) {
    var result = [];
    var options = select && select.options;
    var opt;
  
    for (var i=0, iLen=options.length; i<iLen; i++) {
      opt = options[i];
  
      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    return result.join(',');
  }

  async submit() {
    
    let invalidReasons = []
    let valid = true

    let atchArr = []
    for(let file of this.state.files){
      atchArr.push(this.state.attachments.filter((e=> {return e.name === file.name}))[0])
    }

    if (!this.validateForm()) {
      valid = false
    }

    if( !$('.leadForm').form('is valid', 'firstName')) {
      invalidReasons.push('Please enter your first name.')
    }
    if( !$('.leadForm').form('is valid', 'lastName')) {
      invalidReasons.push('Please enter your last name.')
    }
    if( !$('.leadForm').form('is valid', 'email')) {
      invalidReasons.push('Please enter your email.')
    }
    if(!this.state.contactInfoValid){
      invalidReasons.push('Invalid contact information.')
      valid = false
    }
    if( !$('.leadForm').form('is valid', 'address1')) {
      invalidReasons.push('Please enter your address.')
    }
    if( !$('.leadForm').form('is valid', 'city')) {
      invalidReasons.push('Please enter your city.')
    }
    if( !$('.leadForm').form('is valid', 'state')) {
      invalidReasons.push('Please select your state.')
    }
    if( !$('.leadForm').form('is valid', 'zip')) {
      let countryCode = SettingsUtils.getCountryCode(this.state.company)
      let label = 'zip'
      if(countryCode === 'CA'){
        label = 'postal'
      }
      invalidReasons.push(`Please enter a valid ${label} code.`)
    }
    if( !$('.leadForm').form('is valid', 'phoneNumber')) {
      invalidReasons.push('Please enter your phone number.')
    }

    for(let key in this.requiredElements){
      let requiredElement = this.requiredElements[key]
      if(requiredElement !== undefined){
        let elementRef = this.refs[key]
        if(elementRef instanceof DatePicker) {
          let input = elementRef.input
          if(input && input.value !== undefined && input.value !== null && input.value !== ''){
            
          }else{
            // This is not set
            valid = false
            invalidReasons.push(`${key} is a required field.`)
          }
        } else if(elementRef instanceof Dropdown){
          if(elementRef.state !== undefined && elementRef.state.value !== undefined){
            if(Array.isArray(elementRef.state.value)){
              // multi select
              if(elementRef.state.value.length === 0){
                valid = false
                invalidReasons.push(`${key} is a required field.`)
              }
            }else{
              if(elementRef.state.value.trim() === ''){
                valid = false
                invalidReasons.push(`${key} is a required field.`)
              }
            }
          }else{
            valid = false
            invalidReasons.push(`${key} is a required field.`)
          }
        }else if(elementRef !== undefined && elementRef.id === 'signatureContainer'){
          if(this.sigCanvas !== undefined && this.sigCanvas.isEmpty()){
            invalidReasons.push('Please sign.')
            valid = false
          }
        }else{
          if(elementRef !== undefined && elementRef.value !== undefined && elementRef.value !== null && elementRef.value !== ''){
          }else{
            valid = false
            invalidReasons.push(`${key} is a required field.`)
          }
        }
      }
    }

    this.setState({invalidReasons})

    if(!valid){
      return;
    }

    let supplemental = undefined
    if (Object.keys(this.refs).length > 0) {
      supplemental = {
        mapValue: {
          fields: {}
        }
      }
      for (let d of Object.keys(this.refs)) {
        let elementSupplemental = {
          mapValue: {
            fields: {}
          }
        }
        if (this.refs[d] instanceof DatePicker) {
          let dateFormat = this.refs[d].props.dateFormat   
          let date = this.state.date  
          switch (this.refs[d].props.elementType) {
            case 'serviceDate':
              date = this.state.serviceDate;
              break;
            case 'serviceEndDate':
                date = this.state.serviceEndDate;
                break;
          }
          if(date === undefined ){
            continue
          }
          let dateString = moment(date).format('LL')
          if(dateFormat === 'LLL'){
            dateString = moment(date).format('LLL')
          }
          elementSupplemental.mapValue.fields.value = { 'stringValue': dateString }
          elementSupplemental.mapValue.fields.type = { 'stringValue': this.refs[d].props.elementType }
          elementSupplemental.mapValue.fields.index = {'integerValue': this.refs[d].props.index }
          supplemental.mapValue.fields[d] = elementSupplemental
        } else if(this.refs[d] instanceof Dropdown){
          let ref = this.refs[d]
          if(ref.state !== undefined && ref.state.value !== undefined){
            let value = ref.state.value
            if(this.refs[d].props.elementType === 'multiSelect'){
              let stringArray = ''
              for(let key in value){
                let v = value[key]
                if(stringArray !== ''){
                  stringArray += ', '
                }
                stringArray += v
              }
              value = stringArray
            }

            elementSupplemental.mapValue.fields.value = { 'stringValue':value }
            elementSupplemental.mapValue.fields.type = { 'stringValue': this.refs[d].props.elementType }
            elementSupplemental.mapValue.fields.index = {'integerValue': this.refs[d].props.index }
            if(this.refs[d].props.mapping !== null && this.refs[d].props.mapping !== undefined){
              elementSupplemental.mapValue.fields.mapping = {'stringValue': this.refs[d].props.mapping  }
            }
            supplemental.mapValue.fields[d] = elementSupplemental
          }
        }else {
          if(this.refs[d] && this.refs[d].id === 'signatureContainer'){
            // Skip this..... we handle it below.
            continue
          }
          let value = this.refs[d].value
          if(this.refs[d].getAttribute('elementType') === 'multiSelect'){
            value = this.getSelectValues(this.refs[d])
            let stringArray = ''
            for(let key in value){
              let v = value[key]
              if(stringArray !== ''){
                stringArray += ', '
              }
              stringArray += v
            }
            value = stringArray
          }
          let indexValue = 0
          try{
            indexValue = Number(this.refs[d].getAttribute('index'))
          }catch(e){
            
          }
          elementSupplemental.mapValue.fields.value = { 'stringValue': value }
          elementSupplemental.mapValue.fields.type = { 'stringValue': this.refs[d].getAttribute('elementType') }
          elementSupplemental.mapValue.fields.index = {'integerValue': indexValue }
          if(this.refs[d].getAttribute('mapping') !== null && this.refs[d].getAttribute('mapping') !== undefined){
            elementSupplemental.mapValue.fields.mapping = {'stringValue': this.refs[d].getAttribute('mapping')  }
          }
          supplemental.mapValue.fields[d] = elementSupplemental
        }
      }
    }
    let serviceAddress = undefined
    if(this.state.lead.serviceAddress !== undefined){
      let sa = this.state.lead.serviceAddress
      if(sa !== undefined && this.isAddressValid(sa)){
        serviceAddress = {
          "mapValue": {
            "fields": {
              "zip": {
                "stringValue": this.getAddressZipValue(sa)
              },
              "addressLine1": {
                "stringValue": this.getAddressLine1Value(sa)
              },
              "city": {
                "stringValue": this.getAddressCityValue(sa)
              },
              "county": {
                "stringValue": this.getAddressCountyValue(sa)
              },
              "countyVerbose": {
                "stringValue": this.getAddressCountyVerboseValue(sa)
              },
              "location": {
                "mapValue": {
                  "fields": {
                    "latitude": {
                      "integerValue": (sa.location && sa.location.lat) ? sa.location.lat : 0
                    },
                    "longitude": {
                      "integerValue": (sa.location && sa.location.lng) ? sa.location.lng : 0
                    }
                  }
                }
              },
              "state": {
                "stringValue": this.getAddressStateValue(sa)
              }
            }
          }
        }
      }
    }

    let isBusiness = false
    if(this.state.lead.businessName !== undefined && this.state.lead.businessName !== ''){
      isBusiness = true
    }

    let attachments = {arrayValue : { values: []}}
    for(let item of atchArr){
            
      attachments.arrayValue.values.push(
        {
          "mapValue": {
            "fields": {
              "name": {
                "stringValue": item.name
              },
              "preview": {
                "stringValue": item.preview
              },
              "src": {
                "stringValue": item.src
              },
              "type": {
                "stringValue": item.type
              }
            }
          }
        }
      )
      
    }

    let client = {
      fields: {
        "name": {
          "mapValue": {
            "fields": {
              "first": {
                "stringValue": this.state.lead.name.first
              },
              "last": {
                "stringValue": this.state.lead.name.last
              }
            }
          }
        },
        "address": {
          "mapValue": {
            "fields": {
              "zip": {
                "stringValue": this.getAddressZipValue(this.state.address)
              },
              "addressLine1": {
                "stringValue": this.getAddressLine1Value(this.state.address)
              },
              "city": {
                "stringValue": this.getAddressCityValue(this.state.address)
              },
              "county": {
                "stringValue": this.getAddressCountyValue(this.state.address)
              },
              "countyVerbose": {
                "stringValue": this.getAddressCountyVerboseValue(this.state.address)
              },
              "location": {
                "mapValue": {
                  "fields": {
                    "latitude": {
                      "integerValue": (this.state.address && this.state.address.location && this.state.address.location.lat) ? this.state.address.location.lat : 0
                    },
                    "longitude": {
                      "integerValue": (this.state.address && this.state.address.location && this.state.address.location.lng) ? this.state.address.location.lng : 0
                    }
                  }
                }
              },
              "state": {
                "stringValue": this.getAddressStateValue(this.state.address)
              }
            }
          }
        },
        serviceAddress,
        supplemental,
        date: { "integerValue": moment().valueOf() },
        active: { "booleanValue": true },
        email: { "stringValue": this.state.lead.email },
        category: { "stringValue": "Web" },
        statusId: { "integerValue": 0 },
        teamId: { "stringValue": this.state.data.teamId },
        isBusiness: { "booleanValue": isBusiness },
        attachments
      }
    }

    if(this.state.fid){
      client.fields["formMessageId"] = { "stringValue": this.state.fid }
    }

    if(this.state.formId){
      client.fields["formId"] = { "stringValue": this.state.formId }
    }

    if(this.state.lead.phone !== undefined && this.state.lead.phone.areaCode !== undefined && this.state.lead.phone.number !== undefined){
      client.fields["phone"] = {
        "mapValue": {
          "fields": {
            "areaCode": {
              "stringValue": this.state.lead.phone.areaCode
            },
            "number": {
              "stringValue": `${this.state.lead.phone.number}`
            }
          }
        }
      }

      if(this.state.lead.preferences && this.state.lead.preferences.textNotificationOptIn !== undefined){
        client.fields["preferences"] = {
          "mapValue": {
            "fields": {
              "textNotificationOptIn": {
                "booleanValue": this.state.lead.preferences.textNotificationOptIn
              },
            }
          }
        }
      }
    }

    if(isBusiness){
      client.fields["businessName"] = {
        "stringValue": this.state.lead.businessName
      }
    }

    this.setState({ postingData: true, name: this.state.lead.name.first })

    try{
      client.fields["host"] = {
        "stringValue": this.getOriginUrl()
      }
    }catch(e){}
    try{
      if(this.sigCanvas !== undefined){
        let sigImage = this.sigCanvas.toDataURL("image/png", 0.5)
        let signature = await storage.uploadSignature(this.state.data.teamId, `/leads/${moment().valueOf()}`, sigImage )   
        client.fields["signature"] = {
          "stringValue": signature
        }
      }

      let res = await axios.post(`${api}/leads`, JSON.parse(JSON.stringify(client)));
    }catch(e){
      this.sendFailure(e)
    }
    
    this.setState({ showForm: false, postingData: false })
    
    if(this.state.data.redirectURL){

      if (window.self !== window.top) { // checking if it is an iframe
          window.parent.location = this.state.data.redirectURL;
      } else {
          window.location = this.state.data.redirectURL
      }      
    }

  }

  getOriginUrl() {
    var href = document.location.href;
    var referrer = document.referrer;
    // Check if window.frameElement not null
    if(window.frameElement) {
        href = window.frameElement.ownerDocument.location.href;
        // This one will be origin
        if(window.frameElement.ownerDocument.referrer != "") {
            referrer = window.frameElement.ownerDocument.referrer;
        }
    }
    // Compare if href not equal to referrer
    if(href != referrer) {
        // Take referrer as origin
        return referrer;
    } else {
        // Take href
        return href
    }
}


  sendFailure (error){
    // You can also log error messages to an error reporting service here
    const errorAPI = "https://hooks.slack.com/services/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"
    let mesg = {
      "blocks": [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": `${window.location.href}   =====    PROBLEM SUBMITTING FORM`
          }
        },
        {
          "type": "divider"
        },
        {
          "type": "context",
          "elements": [
            {
              "type": "mrkdwn",
              "text": `\`${error && error.toString().slice(0, 600)}\``
            }
          ]
        }
      ]
    }
    // request.post(errorAPI, mesg, (error, res, body) => { })
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(mesg)
    };
    fetch(errorAPI, requestOptions);
  }

  
  isAddressValid(address) {
    let valid = true
    if(address !== undefined && address !== null) {
        if(address.addressLine1 === undefined || address.addressLine1 === '') {
            valid = false
        }
        if(address.city === undefined || address.city === '') {
            valid = false
        }
        if(address.state === undefined || address.state === '') {
            valid = false
        }
        if(address.zip === undefined || address.zip === '') {
            valid = false
        }
    }else{
        valid = false
    }
    return valid
  }

  renderdropdownOptions(element) {
    let options = []

    for (let key in element.options) {
      let option = element.options[key]
      options.push({key: option, text: option, value: option})
      // options.push(
      //   <option key={option} value={option}>{option}</option>
      // )
    }
    return options
  }

  renderMultiSelectOptions(element) {
    let options = []

    for (let key in element.options) {
      let option = element.options[key]
      options.push({key: option, text: option, value: option})
      // options.push(
      //   <option value={option}>{option}</option>
      // )
    }
    return options
  }

  getDumpsterSizes(dSizes, costTemplates, company) {
    let dumpsterSizes = []

    let companyDumpsterSizes = []
    if(company !== undefined && company.addOnProperties !== undefined &&
        company.addOnProperties.dumpsterSizes !== undefined){
          companyDumpsterSizes = company.addOnProperties.dumpsterSizes
    }

    if(companyDumpsterSizes.length > 0){
      for(let key in companyDumpsterSizes){
          let companyDumpsterSizeValue = companyDumpsterSizes[key]
          for(let dSizeKey in dSizes){
              let ds = dSizes[dSizeKey]
              if(ds.value === companyDumpsterSizeValue){
                  dumpsterSizes.push(ds)
              }
          }
      }
      return dumpsterSizes
    }

    let sizes = []
    for (let key in dSizes) {
      let ds = dSizes[key]
      for (let costTemplateKey in costTemplates) {
        let costTemplate = costTemplates[costTemplateKey]
        if (costTemplate.dumpsterSize === ds.value && !sizes.includes(costTemplate.dumpsterSize)) {
          dumpsterSizes.push(ds)
          sizes.push(costTemplate.dumpsterSize)
          continue
        }
      }
    }

    return dumpsterSizes
  }

  // getDumpsterRentalLengths(lengths, costTemplates) {
  //   let rentalLengths = []
  //   let ls = []

  //   let selectedDumpsterSize = this.state.selectedDumpsterSize
  //   if(selectedDumpsterSize !== undefined){
  //     try{
  //       let indexOf = selectedDumpsterSize.indexOf(' Yard')
  //       selectedDumpsterSize = Number(selectedDumpsterSize.substring(0, indexOf))
  //     }catch(e){
  //       selectedDumpsterSize = undefined
  //     }
  //   }

  //   for (let key in costTemplates) {
  //     let costTemplate = costTemplates[key]
  //     if(costTemplate.category === 'Dumpster'){
  //       for(let lengthKey in lengths){
  //         let length = lengths[lengthKey]
  //         if((selectedDumpsterSize === undefined || selectedDumpsterSize === costTemplate.dumpsterSize) && length.value === costTemplate.dumpsterRentalLength && !ls.includes(length.key)){
  //             ls.push(length.key)
  //             rentalLengths.push(length)
  //         }
  //       }
  //     }
  //   }

  //   let sortedDumpsterRentalLengths = rentalLengths.sort((a,b)=> {
  //     if (a.key < b.key)
  //         return -1;
  //     if (a.key > b.key)
  //         return 1;
  //     return 0;
  //   })

  //   return sortedDumpsterRentalLengths
  // }

  getDumpsterRentalLengths(lengths, costs) {
    let options = []

    //First check if the there are templates with matching this size.
    let selectedDumpsterSize = this.state.selectedDumpsterSize
    if(selectedDumpsterSize !== undefined){
      try{
        let indexOf = selectedDumpsterSize.indexOf(' Yard')
        selectedDumpsterSize = Number(selectedDumpsterSize.substring(0, indexOf))
      }catch(e){
        selectedDumpsterSize = undefined
      }
    }

    let ls = []
    for (let key in costs) {
      let cost = costs[key]
      if(cost.category === 'Dumpster'){
        for(let lengthKey in lengths){
            let length = lengths[lengthKey]
            if((selectedDumpsterSize === undefined || selectedDumpsterSize === cost.dumpsterSize) && length.value === cost.dumpsterRentalLength && !ls.includes(length.key)){
                ls.push(length.key)
                options.push(length)
            }
        }
      }
    }

    if (options.length === 0) { // If no options for rental lengths were found
      let rentalLengths = SettingsUtils.getRentalLengths(this.state.company);
      if (rentalLengths.length > 0) {
        for (let key in rentalLengths) {
          let rentalLengthValue = rentalLengths[key];
          for (let lengthKey in lengths) {
            let length = lengths[lengthKey];
            if (length.value === rentalLengthValue) {
              options.push(length);
            }
          }
        }
      }
    }

    let sortedOptions = options.sort((a,b)=> {
      if (a.key < b.key)
          return -1;
      if (a.key > b.key)
          return 1;
      return 0;
    })

    return sortedOptions
}
    
  getDumpsterLocales(costs){
    let locales = []
    for (let key in costs) {
        let cost = costs[key]
        // AdditionalName is a locale
        if(cost.category === 'Dumpster' && cost.additionalName !== undefined && cost.additionalName.trim() !== '' && !locales.includes(cost.additionalName)){
            locales.push(cost.additionalName)
        }
    }

    let sortedLocales = locales.sort(function(a, b){
        if (a.toLowerCase() < b.toLowerCase())
            return -1;
        if (a.toLowerCase() > b.toLowerCase())
            return 1;
        return 0;
    });

    return sortedLocales
  }

  renderLeadTypeOptions(){
    let options = []

    let leadTypes = []
    if(this.state.company !== undefined && this.state.company.clientProperties !== undefined && this.state.company.clientProperties.leadTypes !== undefined){
      leadTypes = this.state.company.clientProperties.leadTypes
    }
    for (let key in leadTypes) {
      let leadType = leadTypes[key]
      options.push({key: leadType, text: leadType, value: leadType})
    }

    return options
  }

  renderDumpsterSizeOptions(){
    let options = []

    let dumpsterSizes = this.getDumpsterSizes(this.state.dumpsterSizes, this.state.company.templates, this.state.company)

    for (let key in dumpsterSizes) {
      let dumpsterSize = dumpsterSizes[key]
      options.push({key: dumpsterSize.key, text: dumpsterSize.text, value: dumpsterSize.text})
    }

    return options
  }

  renderDumpsterRentalLengthOptions(showDropdownDescription){
    let options = []

    let dumpsterRentalLengths = this.getDumpsterRentalLengths(this.state.dumpsterRentalLengths, this.state.company.templates)

    let sortedDumpsterRentalLengths = dumpsterRentalLengths.sort((a, b) => {
        return (a.key > b.key) ? 1 : -1
    })

    for(let key in sortedDumpsterRentalLengths){
        let dumpsterRentalLength = sortedDumpsterRentalLengths[key]
        let description = this.getDumpsterRentalLengthDescriptionByValue(dumpsterRentalLength.value)
        options.push({key: dumpsterRentalLength.key, text: dumpsterRentalLength.text, value: dumpsterRentalLength.text, description: showDropdownDescription ? description : undefined})
    }

    return options
  }

  getDumpsterRentalLengthDescriptionByValue(value){
    let selectedDumpsterSize = this.state.selectedDumpsterSize
    if(selectedDumpsterSize !== undefined){
      try{
        let indexOf = selectedDumpsterSize.indexOf(' Yard')
        selectedDumpsterSize = Number(selectedDumpsterSize.substring(0, indexOf))
      }catch(e){
        selectedDumpsterSize = undefined
      }
    }

    let description = undefined
    for (let key in this.state.company.templates) {
        let costTemplate = this.state.company.templates[key]
        if(costTemplate.category === 'Dumpster'){
            if((selectedDumpsterSize === undefined || selectedDumpsterSize === costTemplate.dumpsterSize) && value === costTemplate.dumpsterRentalLength){
                if(description !== undefined){
                    description = undefined
                }else{
                    description = costTemplate.description
                }
            }
        }
    }
    return description
  }

  getDumpsterRentalLengthDescriptionByText(text){
    let description = undefined
    for(let key in this.state.dumpsterRentalLengths){
      let dumpsterRentalLength = this.state.dumpsterRentalLengths[key]
      if(text === dumpsterRentalLength.text){
        description = this.getDumpsterRentalLengthDescriptionByValue(dumpsterRentalLength.value)
      }
    }
    return description
  }

  handleDumpsterRentalLengthChange(e, dropdown){
    let value = dropdown.value
    let currentTime = moment().valueOf()
    let diff = currentTime - this.lastDumpsterRentalLength
    this.lastDumpsterRentalLength = currentTime
    if(diff > 100){
        this.setState({selectedRentalLength: value, showDropDowns: false})

        let _this = this
        setTimeout(function(){ 
            _this.setState({showDropDowns: true, selectedRentalLength: value})
        }, 1);
    }
  }

  handleDumpsterSizeChange(e, dropdown){
    let value = dropdown.value
    let currentTime = moment().valueOf()
    let diff = currentTime - this.lastDumpsterSize
    this.lastDumpsterSize = currentTime
    if(diff > 100){
      this.setState({selectedDumpsterSize: value, showDropDowns: false})

      let _this = this
      setTimeout(function(){ 
          _this.setState({showDropDowns: true, selectedDumpsterSize: value})
      }, 1);
    }
  }

  renderDumpsterLocaleOptions(){
    let options = []

    let dumpsterLocales = this.getDumpsterLocales(this.state.company.templates)

    for (let key in dumpsterLocales) {
      let dumpsterLocale = dumpsterLocales[key]
      options.push(
        <option key={dumpsterLocale} value={dumpsterLocale}>{dumpsterLocale}</option>
      )
    }

    return options
  }

  async onDrop(files) {
    let newFilesArray = []
    let attachments = []
    for(let i = 0; i < this.state.files.length; i++){
        let file = this.state.files[i]
        newFilesArray.push(file)
    }
    for(let x = 0; x < this.state.attachments.length; x++){
      let url = this.state.attachments[x]
      attachments.push(url)
  }

    for(let i = 0; i < files.length; i++){
        let f = await this.resizeImage({
          file: files[i],
          maxSize: 1024
        });      
        let p = await this.resizeImage({
          file: files[i],
          maxSize: 50
        });      
        let atch={
          name: files[i].name,
          preview: await this.uploadImage(files[i], p, true),
          src: await this.uploadImage(files[i], f),
          type: files[i].type
        }
        attachments.push( atch )
        newFilesArray.push(files[i])        
    }
    this.setState({files: newFilesArray, attachments});  
  }

  async uploadImage(file, resizedFile, isThumb){
    return new Promise( async (resolve) =>{
      let filePath = `public/company/${this.state.data.teamId}/files/forms/images/${file.lastModified}/${isThumb ? 'thumb_' : ''}${file.name}`
      const url = await storage.uploadFileToPath(filePath,resizedFile)
      resolve(url)
    })
  }


  resizeImage(settings) {
    var file = settings.file;
    var maxSize = settings.maxSize;
    var reader = new FileReader();
    var image = document.createElement('img');
    var canvas = document.createElement('canvas');
    var dataURItoBlob = function (dataURI) {
        var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
            atob(dataURI.split(',')[1]) :
            unescape(dataURI.split(',')[1]);
        var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var max = bytes.length;
        var ia = new Uint8Array(max);
        for (var i = 0; i < max; i++)
            ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
    };
    var resize = function () {
        var width = image.width;
        var height = image.height;
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        return dataURItoBlob(dataUrl);
    };
    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.onload = function (readerEvent) {
            image.onload = function () { return ok(resize()); };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};

  imagesSelected(images, index){
    if(typeof(index) === "undefined"){
        index = 0
    }
    this.setState({lightboxImages: images, lightboxPhotoIndex: index, lightboxIsOpen: true})
  }

  getElementRenderer(element) {
    if (element.type === 'name') {
      if(element.header === undefined){
        element.header = 'Name'
      }
      if(element.showHeader === undefined){
          element.showHeader = false
      }
      if(element.showCompany === undefined){
        element.showCompany = true
      }
      return (
        <div className={``} key={element.id}>
          {element.showHeader ? <h4 className="ui dividing header">{element.header}</h4> : undefined }
          <NameForm 
            entity={this.state.lead} 
            showLabels={true} 
            oneIsRequired={true} 
            personalOnly={!element.showCompany}
            nameChanged={(valid, name, businessName) => { this.updateName(valid, name, businessName) }} 
          />
        </div>
      )
    } else if (element.type === 'contact') {
      if(element.header === undefined){
        element.header = 'Contact'
      }
      if(element.showHeader === undefined){
          element.showHeader = false
      }
      if(element.showEmail === undefined){
        element.showEmail = true
      }
      if(element.showPhone === undefined){
          element.showPhone = true
      }
      return (
        <div className={``} key={element.id}>
          {element.showHeader ? <h4 className="ui dividing header">{element.header}</h4> : undefined }
          {element.showEmail || element.showPhone ?
              <ContactInfoForm 
                entity={this.state.lead}
                company={this.state.company}
                showLabels={true}
                oneIsRequired={true}
                emailOnly={element.showEmail && !element.showPhone} 
                phoneOnly={!element.showEmail && element.showPhone} 
                contactInfoChanged={(params) => { this.updateContactInfo(params) }}
              />
          : undefined }
        </div>
      )
    } else if (element.type === 'address') {
      if(element.header === undefined){
        element.header = 'Address'
      }
      if(element.showHeader === undefined){
          element.showHeader = false
      }
      return (
        <div className={`rm`} key={element.id}>
          {element.showHeader ? <h4 className="ui dividing header">{element.header}</h4> : undefined }
          <AddressForm 
            id="addressForm" 
            entity={this.state.lead ? this.state.lead.address : {}} 
            showLabels={true} 
            company={this.state.company}
            addressChanged={(valid, address) => { this.updateAddress(valid, address) }} 
          />
        </div>
      )
    } 
    else if(element.type === 'serviceAddress'){
      if(element.header === undefined){
        element.header = 'Service Address'
      }
      if(element.showHeader === undefined){
          element.showHeader = true
      }
      let containsAddy = false
      for(let el of this.state.elements){
          if(el.type === 'address'){
              containsAddy = true;
              break;
          }
      }
      return (
        <div className={`rm`} key={element.id}>
          {element.showHeader ? <h4 className="ui dividing header">
              {element.header}
              {containsAddy ? <span style={{color:'#aaa', fontWeight: '300'}}>&nbsp;&nbsp;&nbsp;(If different from billing address.)</span>
                  : undefined}
              </h4> : undefined }
          <AddressForm 
                  id="serviceAddressForm"
                  autoComplete="off"
                  hideSecondLine={true}
                  company={this.state.company}
                  entity={this.state.lead ? this.state.lead.serviceAddress : {}} 
                  showLabels={true} 
                  addressChanged={(valid, address) => { this.updateServiceAddress(valid, address) }}
              />
        </div>
      )
    } else if (element.type === 'dropdown') {
      return (
        <div className="field" key={element.id}>
          <label>{element.label}</label>
          <Dropdown
            ref={element.label} 
            elementType={element.type}
            index={element.index}
            mapping={element.mapping}
            hidden
            placeholder='Select...'
            fluid
            selection
            options={this.renderdropdownOptions(element)}
          />
        </div>
      )
    } else if (element.type === 'multiSelect') {
      return (
        <div className="field" key={element.id}>
          <label>{element.label}</label>
          <Dropdown
            ref={element.label} 
            elementType={element.type}
            index={element.index}
            mapping={element.mapping}
            hidden
            placeholder='Select...'
            fluid
            selection
            multiple
            options={this.renderMultiSelectOptions(element)}
          />
        </div>
      )
    } else if (element.type === 'leadTypeDropdown') {
      let leadOptions = this.renderLeadTypeOptions()
      if(leadOptions.length > 0){
        return (
          <div className="field" key={element.id}>
            <label>{element.label}</label>
            <Dropdown
                ref={element.label} 
                elementType={element.type}
                index={element.index}
                hidden
                placeholder='Select...'
                fluid
                selection
                options={leadOptions}
              />
          </div>
        )
      }
    } else if (element.type === 'dumpsterSizeDropdown') {
      return (
        <div className="field" key={element.id}>
          <label>{element.label}</label>
          <Dropdown
              ref={element.label} 
              elementType={element.type}
              index={element.index}
              hidden
              placeholder='Select Dumpster Size...'
              fluid
              selection
              value={this.state.selectedDumpsterSize}
              options={this.renderDumpsterSizeOptions()}
              onChange={(e, value) => {this.handleDumpsterSizeChange(e, value)}}
            />
        </div>
      )
    } else if (element.type === 'dumpsterRentalLengthDropdown') {
      let dumpsterRentalLengthDescription = this.getDumpsterRentalLengthDescriptionByText(this.state.selectedRentalLength)
      return (
        <div className="field" key={element.id}>
          <label>{element.label}</label>
          {this.state.showDropDowns ?
              <Dropdown
                ref={element.label} 
                elementType={element.type}
                index={element.index}
                hidden
                placeholder='Select Rental Length...'
                fluid
                selection
                value={this.state.selectedRentalLength}
                options={this.renderDumpsterRentalLengthOptions(element.showDropdownDescription)}
                // onChange={this.handleDumpsterRentalLengthChange}
                onChange={(e, value) => {this.handleDumpsterRentalLengthChange(e, value)}}
              />
          : undefined }
          {element.showDropdownDescription && dumpsterRentalLengthDescription !== undefined ?
            <div style={{marginTop: '.25em', fontStyle: 'italic', color: '#999'}}>
              {dumpsterRentalLengthDescription}
            </div>
          : undefined }
        </div>
      )
    } else if (element.type === 'dumpsterLocaleDropdown') {
      return (
        <div className="field" key={element.id}>
          <label>{element.label}</label>
          <select className="ui fluid dropdown" ref={element.label} elementType={element.type} index={element.index}>
            <option key="select" value="" >{`Select...`}</option>
            {this.renderDumpsterLocaleOptions()}
          </select>
        </div>
      )
    }else if(element.type === 'link'){
      return (
        <div className="field" key={element.id}>
          <div className="field">
            <SelectableText 
              text={element.addressLabel}
              color={'#4183c4'} 
              fontSize={14} 
              button={false}
              selected={() => {window.open(element.address, '_blank')}}
            />
          </div>
        </div>
      )
    }else if (element.type === 'input') {
      return (
        <div className="field" key={element.id}>
          <label>{element.label}</label>
          <input
            type="text"
            placeholder={element.label}
            autoComplete="nope"
            ref={element.label} 
            elementType={element.type}
            index={element.index}
            mapping={element.mapping}
          />
        </div>
      )
    }else if (element.type === 'imageUploader') {
      let attachments = undefined
        if(this.state.files.length > 0){
            attachments = 
              <EditableAttachments 
                  attachments={this.state.files} 
                  imagesSelected={(imageURLs, tempIndex) => {this.imagesSelected(imageURLs, tempIndex)}}
                  attachmentsChanged={(newAttachmentsArray) => {this.setState({files: newAttachmentsArray})}}
              />
        }
      return (
        <div className="field" key={element.id}>
          <label>{element.label}</label>
           <Dropzone key={'imageUploader'} onDrop={this.onDrop.bind(this)} 
                  multiple={false}
                  style={{ overflow: 'hidden'}}>
            <Button key='imageUploader_button' onClick={(e) => {e.preventDefault()}}>
                Upload Image
            </Button>
          </Dropzone>
          {attachments}
        </div>
      )
    }else if (element.type === 'date' || element.type === 'serviceDate' || element.type === 'serviceEndDate') {
      let value = this.state[element.type]
      if(value === undefined && ((element.showDefaultDate === undefined || element.showDefaultDate === true) && this.state[element.type + '_selectedDate'])){
        value = new Date()
      }
      value = new Date(moment(value).valueOf())

      let datePicker = undefined
      if(element.showDefaultDate === false && !this.state[element.type + '_selectedDate']){
        datePicker = 
          <Button onClick={() => {this.setState({[element.type + '_selectedDate']: true})}}>
            Select Date
          </Button>
      }else{
        if(element.dateOptions.showTimeSelect){
          datePicker = 
            <DatePicker
              style={{ width: '100%' }}
              selected={value}
              onChange={(date)=>{this.setState({ [element.type]: date })}}
              showTimeSelect
              timeFormat="h:mm a"
              timeIntervals={element.dateOptions.timeIntervals}
              dateFormat="LLLL d, y @ h:mm a"
              timeCaption="Time"
              ref={element.label}
              elementType={element.type}
              index={element.index}
              filterDate={(date) => {return TimeUtils.filterBusinessHoursDate(this.state.company, date)}}
              filterTime={(time) => {return TimeUtils.filterBusinessHoursTime(this.state.company, time)}}
              minDate={new Date()}
            /> 
        }else{
          datePicker = 
            <DatePicker
              style={{ width: '100%' }}
              selected={value}
              onChange={(date)=>{this.setState({ [element.type]: date })}}
              dateFormat="LLLL d, y"
              value={value}
              ref={element.label}
              elementType={element.type}
              index={element.index}
              filterDate={(date) => {return TimeUtils.filterBusinessHoursDate(this.state.company, date)}}
              filterTime={(time) => {return TimeUtils.filterBusinessHoursTime(this.state.company, time)}}
              minDate={new Date()}
            />
        }
      }
      return (
        <div className="field" key={element.id}>
          <label>{element.label}</label>
          {datePicker}
        </div>
      )
    }else if (element.type === 'signature') {
      let signatureContainerWidth = 600
      if(document.getElementById('signatureContainer')){
          signatureContainerWidth = document.getElementById('signatureContainer').offsetWidth
      }else{
          let _this = this
          setTimeout(() => {
              _this.forceUpdate()
          }, 300)
      }
      return (
        <div className="field" key={element.id}>
          <label>{element.label}</label>
          <div id='signatureContainer' 
            ref={element.label}
            elementType={element.type}
            index={element.index}
            style={{width: '100%', marginTop: '2em'}}>
            <div style={{border: '1px solid'}}>
                <SignatureCanvas 
                    ref={(ref) => { this.sigCanvas = ref }}
                    penColor='#1e5ead'
                    canvasProps={{width: signatureContainerWidth, height: 200, className: 'sigCanvas'}} 
                />
            </div>
            <div style={{paddingTop: '1em', display: 'flex', justifyContent: 'center', fontFamily: 'Roboto', fontWeight: '300'}}>
                <button className="ui button noprint"
                    onClick={(e) => { e.preventDefault();this.sigCanvas.clear() }}>
                    Clear
                </button>
            </div>
          </div>
        </div>
      )
    }
  }

  dateChanged(date) {
    this.setState({ date: date })
  }

  renderElements() {
    this.requiredElements = {}

    let elements = []
    let sortedElements = this.state.elements.sort((a, b) => {
      if (a.index < b.index)
        return -1;
      if (a.index > b.index)
        return 1;
      return 0;
    })

    for (let key in sortedElements) {
      let element = sortedElements[key]
      let maxHeight = '120px'
      if (element.type === 'dropdown' || element.type === 'input' || element.type === 'multiSelect') {
        maxHeight = '150px'
      }
      // if(element.isRequired && element.type !== 'date'){
      if(element.isRequired){
        if(element.type === 'leadTypeDropdown'){
          let leadOptions = this.renderLeadTypeOptions()
          if(leadOptions.length > 0){
            this.requiredElements[element.label] = true
          }
        }else{
          this.requiredElements[element.label] = true
        }
      }
      elements.push(this.getElementRenderer(element))
    }

    return elements
  }

  renderLegalLinks(){
    let bname = ""
    try{
      bname = this.state.company.properties.legalBusinessName || this.state.company.properties.businessName
    }catch(e){}
    return (<div style={{ paddingTop: '2em', 'fontWeight': 300,
    'textAlign': 'center' }}>
    By submitting this form, you agree to {bname}'s&nbsp;
    <LegalModal api={api} linkTitle="Terms" legalType="TOS" company={this.state.company}>terms</LegalModal> 
    &nbsp;and&nbsp;
    <LegalModal api={api} linkTitle="Privacy" legalType="PRIVACY" company={this.state.company}>privacy</LegalModal>
    &nbsp;policy

      
    </div>);
  }

  render() {
    if(this.state.data === undefined){
      return <FadeIn>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
          <h1>Loading Form</h1>
          <ReactLoading type={'bubbles'} color={'#aaa'} height={120} width={120} />          
        </div>
      </FadeIn>
        // return <div style={{padding:'0% 20%', height:'100%', width:'100%'}}><ReactLoading type={'bubbles'} color={'#aaa'} height={'50%'} width={'100%'} /> </div>
    }
    let phoneLabel = <label>Phone</label>
    let emailLabel = <label>Email</label>
    let streetLabel = <label>Street</label>
    let cityLabel = <label style={{ fontFamily: 'Roboto', fontWeight: 'bold' }}>City</label>
    let stateLabel = <label>State</label>
    let zipLabel = <label>Zip Code</label>

    let thankyouView = <div className="ui centered grid">
      {!this.state.embed && this.state.showHeader ? 
      <div className="sixteen wide column" style={{ paddingTop: '2em', borderBottom: '1px solid #dededf', paddingTop: '1em'  }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="ui small centered image"  style={{marginTop: '1em' }}>
            <img src={this.state.company.properties.image} />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ fontSize: 24, fontFamily: 'Roboto', fontWeight: '500', paddingTop: '1em' }}>
            {this.state.company.properties.businessName}
          </div>
        </div>
      </div>
      :undefined
    }
      <div className="row" style={{ padding: '3em 0em' }}>
        <div className="align-center ">
          <h1 className="">Thank you {this.state.name}</h1>
          <h3 className="">{this.state.submitMessage !== undefined ? this.state.submitMessage : `Your request has been sent and we will contact you shortly.`}</h3>
        </div>
      </div>
    </div>

    let primaryColor = SettingsUtils.getPrimaryColor(this.state.company)
    let secondaryColor = SettingsUtils.getSecondaryColor(this.state.company)

    let normalForm = 
      <div style={{ height: '100%', overflow:'auto' }}>
        <div className="ui stackable centered sixteen column grid">
          {this.state.showHeader ?
            <div className="sixteen wide column" style={{ paddingTop: '2em', paddingTop: '1em' }}>
              <div style={{ backgroundColor: '#FFF', margin: '0em', borderBottom: `1.5em solid ${primaryColor}` }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <a href={this.state.company.properties.website !== undefined ? this.state.company.properties.website : `https://www.yourdocket.com`} target="_blank" style={{alignSelf: "center"}}>
                    <div style={{padding: '.5em', marginLeft: '.5em'}}>
                      <Image 
                        height='80px'             
                        src={this.state.company.properties.image} 
                      />
                    </div>
                  </a>
                  <h1 style={{ display: 'flex', fontFamily: 'Roboto', color: '#444', fontWeight: '300',margin: '0em', paddingLeft: '.25em' }}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '.25em'}}>
                      <Header as='h1' textAlign='right' style={{ color: primaryColor, padding: '.5em', marginBottom: '0em', fontWeight: '300' }}>
                        {this.state.company.properties.businessName}
                      </Header>
                    </div>
                  </h1>
                </div>
              </div>
            </div>
          : undefined }
        {this.state.textHtml !== undefined && this.state.textHtml !== '' ?
        <div className="five wide column" style={{ padding: '1em' }}>
          <div className="quill " style={{ width: "100%" }}>
            <div className="ql-container ql-snow" style={{ border: 'none' }}>
              <div className="ql-editor" data-gramm="false">
                <div dangerouslySetInnerHTML={{ __html: this.state.textHtml }}></div>
              </div>
            </div>
          </div>
        </div>
        : undefined }
        <div className="five wide column" style={{ padding: '1em' }}>
          <div className="ui segment">
            <form className="ui form leadForm">
              {this.renderElements()}
              <div hidden>
                <Dropdown
                  hidden
                  placeholder='Select...'
                  fluid
                  selection
                  options={this.renderDumpsterRentalLengthOptions(false)}
                />
              </div>
              <div className={`ui full button ${this.state.postingData ? 'loading' : ''}`} 
                style={{backgroundColor: primaryColor, color: CommonFunctionsUtils.getContrast(primaryColor)}} 
                onClick={this.submit.bind(this)} 
                tabIndex="0">
                Submit
              </div>
            </form>
            {this.state.invalidReasons.length > 0 ?
                  <Message error>
                    <Message.Header>Invalid Form</Message.Header>
                    <Message.List items={this.state.invalidReasons} />
                  </Message>
              : undefined }
            {this.renderLegalLinks()}
          </div>
        </div>
      </div>
      {this.state.lightboxIsOpen && <Lightbox
          mainSrc={this.state.lightboxImages[this.state.lightboxPhotoIndex]}
          nextSrc={this.state.lightboxImages[(this.state.lightboxPhotoIndex + 1) % this.state.lightboxImages.length]}
          prevSrc={this.state.lightboxImages[(this.state.lightboxPhotoIndex + this.state.lightboxImages.length - 1) % this.state.lightboxImages.length]}
          onCloseRequest={() => this.setState({ lightboxIsOpen: false })}
          onMovePrevRequest={() =>
          this.setState({
              lightboxPhotoIndex: (this.state.lightboxPhotoIndex + this.state.lightboxImages.length - 1) % this.state.lightboxImages.length,
          })
          }
          onMoveNextRequest={() =>
          this.setState({
              lightboxPhotoIndex: (this.state.lightboxPhotoIndex + 1) % this.state.lightboxImages.length,
          })
          }
      />}
    </div>

    let embededForm = <div style={{ height: '100%', overflow: 'auto' }}>
        <div className="ui stackable centered sixteen column grid">
          <div className="sixteen wide column" style={{ paddingTop: '2em', borderBottom: '1px solid #dededf', paddingTop: '1em' }}>
            <div className="ui segment" style={{border:'none', boxShadow:'none'}}>
                  <form className="ui form leadForm">
                    {this.renderElements()}
                    <div className={`ui full button ${this.state.postingData ? 'loading' : ''}`} 
                      style={{backgroundColor: primaryColor, color: CommonFunctionsUtils.getContrast(primaryColor)}} 
                      onClick={this.submit.bind(this)} 
                      tabIndex="0">
                      Submit
                    </div>
                  </form>
                  {this.state.invalidReasons.length > 0 ?
                        <Message error>
                          <Message.Header>Invalid Form</Message.Header>
                          <Message.List items={this.state.invalidReasons} />
                        </Message>
                    : undefined }
                    {this.renderLegalLinks()}
                </div>
          </div>
        </div>
        {this.state.lightboxIsOpen && <Lightbox
            mainSrc={this.state.lightboxImages[this.state.lightboxPhotoIndex]}
            nextSrc={this.state.lightboxImages[(this.state.lightboxPhotoIndex + 1) % this.state.lightboxImages.length]}
            prevSrc={this.state.lightboxImages[(this.state.lightboxPhotoIndex + this.state.lightboxImages.length - 1) % this.state.lightboxImages.length]}
            onCloseRequest={() => this.setState({ lightboxIsOpen: false })}
            onMovePrevRequest={() =>
            this.setState({
                lightboxPhotoIndex: (this.state.lightboxPhotoIndex + this.state.lightboxImages.length - 1) % this.state.lightboxImages.length,
            })
            }
            onMoveNextRequest={() =>
            this.setState({
                lightboxPhotoIndex: (this.state.lightboxPhotoIndex + 1) % this.state.lightboxImages.length,
            })
            }
        />}
      </div>


    let formView = this.state.embed ? embededForm : normalForm
    return this.state.showForm ? formView : thankyouView
  }
}

export default App;
