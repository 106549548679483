class CommonFunctionsUtils {

    validateEmail(email){
        let valid = false
        if(email !== undefined && email !== null && email !== '' && email.includes('@') && (email.split('@').length-1 == 1)){
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            valid = re.test(String(email).toLowerCase());
        }
        return valid
    }

    getContrast(hexcolor){
        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
    
        // If a three-character hexcode, make six-character
        if (hexcolor.length === 3) {
            hexcolor = hexcolor.split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }
    
        // Convert to RGB value
        var r = parseInt(hexcolor.substr(0,2),16);
        var g = parseInt(hexcolor.substr(2,2),16);
        var b = parseInt(hexcolor.substr(4,2),16);
    
        // Get YIQ ratio
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
        // Check contrast
        return (yiq >= 128) ? '#444' : '#FFF';
    }

    isPhoneValid(phone) {
        let phoneValid = false
        try {
            let phoneLength = 0 
            phone = phone || ''
            phone = phone.replace(/[^0-9]/g, "");
            phoneLength = phone.length
            if (phoneLength >= 10) {
                phoneValid = true
            }
        } catch (e) {console.error(e) }
        return phoneValid
    }
}

export default new CommonFunctionsUtils()