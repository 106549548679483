class SettingsUtils {

    getCountryCode(company){
        if(company !== undefined && company.regionalProperties !== undefined &&
            company.regionalProperties.country !== undefined){
                return company.regionalProperties.country
        }else{
            return 'US'
        }
    }

    getPrimaryColor(company){
        if(company !== undefined && company.properties !== undefined &&
            company.properties.primaryColor !== undefined){
            return company.properties.primaryColor
        }else{
            return '#1e5ead'
        }
    }

    getSecondaryColor(company){
        if(company !== undefined && company.properties !== undefined &&
            company.properties.secondaryColor !== undefined){
            return company.properties.secondaryColor
        }else{
            return '#efefef'
        }
    }

    getRentalLengths(company){
        if(company !== undefined && company.addOnProperties !== undefined &&
            company.addOnProperties.rentalLengths !== undefined){
            return company.addOnProperties.rentalLengths
        }else{
            return []
        }
    }

    getEmailNotificationOptIn(company, entity){
        // entity could be customer, contractor, employee
        if(entity !== undefined && entity.preferences && entity.preferences.emailNotificationOptIn !== undefined){
            return entity.preferences.emailNotificationOptIn
        }
        if(company !== undefined && company.clientProperties !== undefined &&
            company.clientProperties.emailNotificationOptIn !== undefined){
            return company.clientProperties.emailNotificationOptIn
        }else{
            return true
        }
    }

    getTextNotificationOptIn(company, entity){
        // entity could be customer, contractor, employee
        if(entity !== undefined && entity.preferences && entity.preferences.textNotificationOptIn !== undefined){
            return entity.preferences.textNotificationOptIn
        }
        if(company !== undefined && company.clientProperties !== undefined &&
            company.clientProperties.textNotificationOptIn !== undefined){
            return company.clientProperties.textNotificationOptIn
        }else{
            return true
        }
    }
}

export default new SettingsUtils()