
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios'
import SettingsUtils from '../SettingsUtils'
import { BrowserView, MobileView, isBrowser, isMobileOnly } from "react-device-detect";
let $ = window.$

class AddressForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  
    constructor(props) {
        super(props);

        this.state = { 
            address: this.props.entity
        };
    }

    componentDidUpdate(previousProps){
        let _this = this
        $(`#${this.props.id} .stateDropdown`).dropdown({
            onChange: function(value, text, $selectedItem){
                _this.updateState(value)
            }
        });

        // $(".stateDropdown").dropdown("set selected", this.getAddressStateValue())

        let address = undefined
        if(this.state.address !== undefined){
            address = JSON.parse(JSON.stringify(this.state.address))
        }
        let changed = false
        if((previousProps.entity === undefined || previousProps.entity.address === undefined) && (this.props.entity !== undefined && this.props.entity.address !== undefined)){
            changed = true
            address = this.props.entity.address
        }else if((previousProps.entity !== undefined && previousProps.entity.address !== undefined) && (this.props.entity !== undefined && this.props.entity.address === undefined)){
            changed = true
            address = this.props.entity.address
        }else if(address !== undefined && this.props.entity !== undefined && this.props.entity.address !== undefined){
            
            if(address.addressLine1 !== this.props.entity.address.addressLine1){
                changed = true
                address.addressLine1 = this.props.entity.address.addressLine1
            }
            if(address.addressLine2 !== this.props.entity.address.addressLine2){
                changed = true
                address.addressLine2 = this.props.entity.address.addressLine2
            }
            if(address.city !== this.props.entity.address.city){
                changed = true
                address.city = this.props.entity.address.city
            }
            if(address.state !== this.props.entity.address.state){
                changed = true
                address.state = this.props.entity.address.state                
            }
            if(address.zip !== this.props.entity.address.zip){
                changed = true
                address.zip = this.props.entity.address.zip
            }
            if(JSON.stringify(address.location) !== JSON.stringify(this.props.entity.address.location)){
                changed = true
                address.location = this.props.entity.address.location
            }
        }

        if(changed){
            this.setState({address})
        }
    }

    componentDidMount(){
        // $('.ui.dropdown')
        //     .dropdown()
        // ;


        this.addressChanged()
    }

    updateAddressLine1(line1){
        let address = this.getAddressClone();
        address.addressLine1 = line1

        this.setState({address: address})
        this.addressChanged(address)
    }

    updateAddressLine2(line2){
        let address = this.getAddressClone();
        address.addressLine2 = line2
  
        this.setState({address: address})
        this.addressChanged(address)
    }

    updateLocation(location){
        let address = this.getAddressClone();
        address.location = {
            latitude: location.lat,
            longitude: location.lng,
        }

        this.setState({address: address})
        this.addressChanged(address)
    }

    updateCity(city){
        let address = this.getAddressClone();
        address.city = city
  
        this.setState({address: address})
        this.addressChanged(address)
    }

    updateCounty(county, countyVerbose){
        let address = this.getAddressClone();
        address.county = county
        address.countyVerbose = countyVerbose
  
        this.setState({address: address})
        this.addressChanged(address)
    }

    updateState(state){
        let address = this.getAddressClone();
        address.state = state
        this.setState({address: address})
        this.addressChanged(address)
    }

    updateZip(zip){
        let address = this.getAddressClone();
        address.zip = zip
  
        this.setState({address: address})
        this.addressChanged(address)
    }

    getAddressClone(){
        if(this.state.address === undefined){
            return {}
        }
        return JSON.parse(JSON.stringify(this.state.address));
    }
  
    addressChanged(address){
        let valid = this.validateForm()
        if(this.props.addressChanged !== undefined){
            this.props.addressChanged(valid, address)
        }
    }

    validateForm(){
        // $(`#${this.props.id}`).form('validate form')

        let valid = false
        if($(`#${this.props.id}`).form('is valid')){
            valid = true
        }

        return valid
    }

    getAddressLine1Value(){
        if(this.props.entity !== undefined && this.state.address !== undefined && this.state.address.addressLine1 !== undefined){
            return this.state.address.addressLine1
        }else{
            return ''
        }
    }

    getAddressLine2Value(){
        if(this.props.entity !== undefined && this.state.address !== undefined && this.state.address.addressLine2 !== undefined){
            return this.state.address.addressLine2
        }else{
            return ''
        }
    }

    getAddressCityValue(){
        if(this.props.entity !== undefined && this.state.address !== undefined && this.state.address.city !== undefined){
            return this.state.address.city
        }else{
            return ''
        }
    }

    getAddressStateValue(){
        if(this.props.entity !== undefined && this.state.address !== undefined && this.state.address.state !== undefined){
            return this.state.address.state
        }else{
            return ''
        }
    }

    getAddressZipValue(){
        if(this.props.entity !== undefined && this.state.address !== undefined && this.state.address.zip !== undefined){
            return this.state.address.zip
        }else{
            return ''
        }
    }

    zipChanged(){
        // if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(this.refs.zip.value)) {
        //     this.getAddressFromZip()
        // }
        // let zip = this.refs.zip.value
        // let cleanZip = zip.replace(/\s/g,'')
        // if(cleanZip.length > 5){
        //     cleanZip = cleanZip.slice(0, 5);
        // }
        // if(zip !== cleanZip){
        //     zip = cleanZip
        //     this.refs.zip.value = zip
        // }
        // this.updateZip(zip)

        let countryCode = SettingsUtils.getCountryCode(this.props.company)

        let zip = ''
        if(this.refs.zip !== undefined){
            zip = this.refs.zip.value
        }
        if(this.isAddressZipValid(countryCode, zip)){
            this.getAddressFromZip()
        }

        if(countryCode === 'US'){
            let cleanZip = zip.replace(/\s/g,'')
            if(cleanZip.length > 5){
                cleanZip = cleanZip.slice(0, 5);
            }
            if(zip !== cleanZip){
                zip = cleanZip
                this.refs.zip.value = zip
            }
        }else if(countryCode === 'CA'){
            let cleanZip = zip
            if(cleanZip.length > 5){
                cleanZip = cleanZip.slice(0, 7);
            }
            if(zip !== cleanZip){
                zip = cleanZip
                this.refs.zip.value = zip
            }
        }
        
        this.updateZip(zip)
    }

    isAddressZipValid(countryCode, zip){
        if(countryCode === 'US'){
            try {
                let valid = true
                if (zip === undefined || zip === '' || zip === 'undefined' || zip.length !== 5) {
                    valid = false
                }
                return valid
            } catch (e) {
                console.error(e)
                return false
            } 
        }else{
            try {
                let valid = true
                if (zip === undefined || zip === '' || zip === 'undefined' || zip.length !== 7) {
                    valid = false
                }
                return valid
            } catch (e) {
                console.error(e)
                return false
            } 
        }
    }

    getAddressFromZip(){
        let _this = this
        let zip = this.refs.zip.value
        let address1 = this.refs.address1.value
        let safeAddress = address1.replace(/\s/g, '+')
        safeAddress = safeAddress.replace(/[^a-zA-Z0-9/-]/g, "");
        let key='AIzaSyDSc11PWwNee7Ss-sb9VKmS13dUO8x8xbg'
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${safeAddress}+${zip}&key=${key}`)
            .then(function (response) {
                // handle success
                console.log("GEOCODEING")
                if(typeof response !== "undefined" && typeof response.data !== "undefined" && 
                    typeof response.data.results !== "undefined"){
                    let results = response.data.results
                    if(results.length > 0){
                        let result = results[0]
                        if(typeof result !== "undefined"){
                            let city = undefined
                            let cityBackup = undefined
                            let cityBackup2 = undefined
                            let county = undefined
                            let countyVerbose = undefined
                            let state = undefined
                            let country = undefined

                            for(let i in result.address_components){
                                let addressComponent = result.address_components[i]
                                for(let j in addressComponent.types){
                                    let type = addressComponent.types[j]
                                    if(type === 'locality'){
                                        city = addressComponent.long_name
                                    }else if(type === 'administrative_area_level_3'){
                                        cityBackup = addressComponent.long_name
                                    }else if(type === 'neighborhood'){
                                        cityBackup2 = addressComponent.long_name
                                    }else if(type === 'administrative_area_level_2'){
                                        countyVerbose = addressComponent.long_name
                                        if(countyVerbose !== undefined && countyVerbose.endsWith('County')){
                                            let index = countyVerbose.indexOf(' County')
                                            county = countyVerbose.substring(0, index)
                                        }
                                    }else if(type === 'administrative_area_level_1'){
                                        let isPuertoRico = false
                                        for(let i in result.address_components){
                                            let internalAddressComponent = result.address_components[i]
                                            for(let j in internalAddressComponent.types){
                                                let internalType = internalAddressComponent.types[j]
                                                if(internalType === 'country' && internalAddressComponent.long_name === 'Puerto Rico'){
                                                    isPuertoRico = true
                                                    break
                                                }
                                            }
                                        }
                                        if(!isPuertoRico){
                                            state = addressComponent.long_name
                                        }
                                    }else if(type === 'country'){
                                        if(addressComponent.long_name === 'Puerto Rico'){
                                            state = addressComponent.long_name
                                        }
                                        country = addressComponent.long_name
                                    }
                                }
                            }

                            if(city === undefined && cityBackup !== undefined){
                                city = cityBackup
                            }else if(city === undefined && cityBackup === undefined && cityBackup2 !== undefined){
                                city = cityBackup2
                            }

                            if(typeof result.geometry !== 'undefined' && typeof result.geometry.location !== 'undefined'){
                                let loc = result.geometry.location
                                _this.updateLocation(loc)
                            }

                            // _this.refs.city.value = city
                            _this.updateCity(city)
                            _this.updateCounty(county, countyVerbose)
                            _this.updateState(state)
                            // _this.refs.state.value = state
                            $(".stateDropdown").dropdown("set selected", state, undefined)
                            // _this.refs.country = country
                            _this.addressChanged()
                            _this.updateZip(zip)
                        }
                    }
                }
            })

            .catch(function (error) {
                // handle error
                console.log(error);
            })

            .then(function () {
                // always executed
            }
        );
    }

  render() {
    let countryCode = SettingsUtils.getCountryCode(this.props.company)
    let zipPlaceholder = 'Zip Code'

    let streetLabel = <label>Street</label>
    let cityLabel = <label>City</label>
    let stateLabel = <label>State</label>
    let provinceLabel = <label>Province</label>
    let zipLabel = <label>Zip Code</label>
    if(countryCode === 'CA'){
        zipPlaceholder = 'Postal Code'
        zipLabel = <label>Postal Code</label>
    }

    if(!this.props.showLabels){
        streetLabel = undefined
        cityLabel = undefined
        stateLabel = undefined
        zipLabel = undefined
    }


    let stateField = undefined
    if(countryCode === 'US'){
        stateField = 
            <div className="six wide field">
                {stateLabel}
                <select ref='state' name='address-level1' className="ui fluid dropdown stateDropdown">
                    <option value="">State</option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="District Of Columbia">District Of Columbia</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                </select>
            </div>
    }

    let provinceField = undefined
    if(countryCode === 'CA'){
        provinceField =
            <div className="six wide field">
                {provinceLabel}
                <select ref='state' name='address-level1' className="ui fluid dropdown stateDropdown">
                    <option value="">Province</option>
                    <option value="Alberta">Alberta</option>
                    <option value="British Columbia">British Columbia</option>
                    <option value="Manitoba">Manitoba</option>
                    <option value="New Brunswick">New Brunswick</option>
                    <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                    <option value="Northwest Territories">Northwest Territories</option>
                    <option value="Nova Scotia">Nova Scotia</option>
                    <option value="Nunavut">Nunavut</option>
                    <option value="Ontario">Ontario</option>
                    <option value="Prince Edward Island">Prince Edward Island</option>
                    <option value="Quebec">Quebec</option>
                    <option value="Saskatchewan">Saskatchewan</option>
                    <option value="Yukon">Yukon</option>
                </select>
            </div>
    }

    return (
        <div className="" style={{margin: '1em 0em'}} id={this.props.id}>
            <div className="field">
                {streetLabel}
                <input ref='address1' type="text" name="address1" placeholder="Street Address Line 1"   autoComplete={this.props.autoComplete ? this.props.autoComplete : "on"}
                       spellCheck="false"
                       value={this.getAddressLine1Value()} onChange={() => {this.updateAddressLine1(this.refs.address1.value)}}/>
            </div>
            {this.props.hideSecondLine ? undefined :
                 <div className="field">
                    <input ref='address2' type="text" name="address2" placeholder="Street Address Line 2"  autoComplete={this.props.autoComplete ? this.props.autoComplete : "on"}
                            spellCheck="false"
                            value={this.getAddressLine2Value()} onChange={() => {this.updateAddressLine2(this.refs.address2.value)}}/>
                </div>
            }
            <div className="three fields" style={{flexFlow: isMobileOnly ? '' : 'nowrap'}}>
                <div className="four wide field">
                    {zipLabel}
                    <input ref='zip' type="text" name="zip" placeholder={zipPlaceholder} autoComplete={this.props.autoComplete ? this.props.autoComplete : "on"}
                           value={this.getAddressZipValue()} onChange={() => {this.zipChanged()}}/>
                </div>
                <div className="six wide field">
                    {cityLabel}
                    <input ref='city' type="text" name="city" placeholder="City"   autoComplete={this.props.autoComplete ? this.props.autoComplete : "on"}
                           spellCheck="false"
                           value={this.getAddressCityValue()} onChange={() => {this.updateCity(this.refs.city.value)}}/>
                </div>
                {stateField}
                {provinceField}
            </div>
        </div>
    );
  }
 
}

AddressForm.propTypes = {
    entity: PropTypes.object,    
    showLabels: PropTypes.bool,
    addressChanged: PropTypes.func,
    hideSecondLine: PropTypes.bool,
}

export default AddressForm
