import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react'

class SelectableText extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  
    constructor(props) {
        super(props);
    }
    
    render() {
        let color = this.props.color ? this.props.color : '#4183c4';

        if(this.props.button){
            return (
                <Button compact onClick={(e) => {this.props.selected(e)}}>
                    {this.props.text}
                </Button>
            )
        }

        return (
            <span style={{fontFamily: 'Roboto', fontWeight: this.props.fontWeight ? this.props.fontWeight : '300', color, fontSize: this.props.fontSize, cursor: 'pointer', borderBottom: this.props.underline ? '1px solid' : undefined}} onClick={(e) => {this.props.selected(e)}}>
                {this.props.text}
            </span>
        )
    }
 
}

SelectableText.propTypes = {
    text: PropTypes.string,
    selected: PropTypes.func,
    fontSize: PropTypes.number,
}

export default SelectableText
