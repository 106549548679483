import { Modal, Button, Icon } from 'semantic-ui-react';
import FireStoreParser from 'firestore-parser';
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const LegalModal = (props) => {
  let { linkTitle, company, legalType, api } = props;
  const [content, setContent] = useState('');
  const [contentDownloaded, setContentDownloaded] = useState();
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [simpleLinkOut, setSimpleLinkOut] = useState();

  async function fetchContent(company, url, contentDownloaded, setContent, setContentDownloaded) {
    try {
      if (!!url && !contentDownloaded) {
        if (url.includes('cdn.yourdocket.com')) {
        //   console.log('Fetching', url);

          const response = await fetch(url);
          let text = await response.text();
          setContentDownloaded(text);
          text = text
            .replace(/\[SENDER\]/g, company.properties.legalBusinessName || company.properties.businessName)
            .replace(/\[CONTACT\]/g, company.properties.email)
            .replace(/\[LINK\]/g, company.properties.privacyUrl);

          setContent(renderContentByExtension(url, text));
        } else {
        //   console.log('Fetching', url);

          let json = await fetch(`${api}/public/${company.teamId}`)
            .then((response) => response.json())
            .then((json) => FireStoreParser(json.fields));

          setContentDownloaded(json);
          if (json?.filename && json.data) {
            setContent(renderContentByExtension(json.filename, json.data));
          }
        }
      }
    } catch (err) {
      setError(err.toString());
    }
  }

  function isMarkdownHtmlOrText(filename) {
    return /\.(md|markdown|html|txt)$/.test(filename);
  }
  function renderContentByExtension(filename, content) {
    const extension = filename.split('.').pop();

    switch (extension) {
      case 'txt':
      //     return 'This is a text file.';
      case 'md':
      case 'markdown':
        return (
          <div className="markdown-content">
            <ReactMarkdown children={content} />
          </div>
        );
      case 'html':
        return <div dangerouslySetInnerHTML={{ __html: content }} />;

      default:
        return error || 'Unknown file type.';
    }
  }

  function checkRenderMode(company, legalType, setSimpleLinkOut) {}

  useEffect(() => {
    // console.log('Mounted');
  }, []);

  useEffect(() => {
    // console.log('Checking linking');

    let url = '';
    if (!!company?.properties?.businessName) {
      // need a business name to verify this company exists, beyond placeholder data.
      switch (legalType) {
        case 'PRIVACY':
          url = company?.properties?.privacyUrl || 'cdn.yourdocket.com/default/privacy.txt';
          break;
        case 'TOS':
          url = company?.properties?.tosUrl || 'cdn.yourdocket.com/default/tos.txt';
          break;
        default:
          break;
      }
    }
    if (!!url) {
      if (!url.toLowerCase().startsWith('http')) {
        url = `https://${url}`; // just going to assume we add https for web address missing the proto
      }
      if (!url.includes('cdn.yourdocket.com')) {
        return setSimpleLinkOut(url);
      } else if (open) {
        fetchContent(company, url, contentDownloaded, setContent, setContentDownloaded);
      }
    }
    // if (!!company?.properties?.businessName) {
    //   url = fetchContent(company, url, contentDownloaded, setContent, setContentDownloaded);
    // }
  }, [company, legalType, open]);

  if (simpleLinkOut) {
    return (
      <a href={simpleLinkOut} target="_blank">
        {props.children}
      </a>
    );
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <a onClick={() => setOpen(false)} href="#">
          {props.children}
        </a>
      }
    >
      <Modal.Header>{linkTitle}</Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description style={{ width: '99%' }}>{content}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} primary>
          Close <Icon name="chevron right" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LegalModal;
