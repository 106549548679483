import { storage, firestore } from './firebase';

  export const uploadFileToDatabaseRef = (key, file, documentPath,  cb) =>{
    let fileRef = storage.ref(documentPath+key);
    return fileRef.put(file).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        firestore.doc(documentPath).update({[key]:downloadURL})
        // firestore.collection('teams').doc(teamId).collection('members').doc(memberId).update({'profilePic': downloadURL}).then(()=> {debugger; cb(downloadURL)})
        // db.ref(`${urlPathInDB}`).set(downloadURL);
        cb(downloadURL)
      });
    });
  }

  export const uploadProfilePic = (teamId, memberId, file, cb) =>{    
    let profileRef = storage.ref(`teams/${teamId}/members/${memberId}/profilepic`);
    profileRef.put(file).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        firestore.collection('teams').doc(teamId).collection('members').doc(memberId).update({'profilePic': downloadURL}).then(()=> {cb(downloadURL)})
        // db.ref(`teams/${teamId}/members/${memberId}`).child('profilePic').set(downloadURL);
      });
    });
  }

  export const uploadFile = (teamId, file, cb) =>{
    let fileRef = storage.ref("company/" +teamId+'/files');
    return fileRef.put(file).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        cb(downloadURL)
      });
    });
  }
  export const uploadFileToPath = (filePath, file, cb) =>{
    let fileRef = storage.ref(filePath);
    return new Promise((resolve, reject) => {
      fileRef.put(file).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve(downloadURL)
        }).catch(e =>{
          console.log(e)
        })
      });
    });
  }

  export const uploadFileWithPath = (teamId, path, file, cb) =>{
    let fileRef = storage.ref("company/" +teamId+'/files/'+path);
    return fileRef.put(file).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        cb(downloadURL)
      });
    });
  }

  export const uploadSignature = (companyID, path, b64String) => {
    debugger
    let fileRef = storage.ref("public/" + companyID + '/signatures' + path);
    return fileRef.putString(b64String.replace('data:image/png;base64,',''),'base64', {contentType:'image/png'}).then(async (snapshot) => {
      return await storage.ref(snapshot.metadata.fullPath).getDownloadURL()
    });
  }