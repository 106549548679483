class CommunicationUtils {

    convertToSMSNumber(phoneObj){
        if(!phoneObj){
            return undefined
        }
        function validE164(num) {
            return /^\+?[1-9]\d{1,14}$/.test(num)
        }
        let phoneNumber = "+1" + (phoneObj.areaCode + "" + phoneObj.number).replace('-', '')
        if (!validE164(phoneNumber)) {
            return undefined
        }
        else{
            return phoneNumber
        }
    }

    convertToPhoneNumber(smsNumber){
        if(smsNumber === undefined){
            return ''
        }
        
        let strippedPhoneNumber = smsNumber.replace(/[^0-9/.]/g, "");

        if(strippedPhoneNumber.length === 11){
            strippedPhoneNumber = strippedPhoneNumber.substr(1)
        }

        if(strippedPhoneNumber.length > 0){
            strippedPhoneNumber = '(' + strippedPhoneNumber
        }
        if(strippedPhoneNumber.length > 4){
            strippedPhoneNumber = strippedPhoneNumber.slice(0, 4) + ')' + strippedPhoneNumber.slice(4);
        }
        if(strippedPhoneNumber.length > 8){
            strippedPhoneNumber = strippedPhoneNumber.slice(0, 8) + '-' + strippedPhoneNumber.slice(8);
        }
        if(strippedPhoneNumber.length >= 13){
            strippedPhoneNumber = strippedPhoneNumber.slice(0, 13);
        }

        strippedPhoneNumber = strippedPhoneNumber.replace(')', ') ')
        
        return strippedPhoneNumber
    }
}

export default new CommunicationUtils()