import firebase from 'firebase';
import '@firebase/firestore'

const prodConfig = {
  apiKey: "AIzaSyDsAXJOwdFMP0sOa2mZ4jdzfSFKFDtsZm4",
  authDomain: "www.yourdocket.com",
  databaseURL: "https://docket-3257f.firebaseio.com",
  projectId: "docket-3257f",
  storageBucket: "docket-3257f.appspot.com",
  messagingSenderId: "446074423165",
  timestampsInSnapshots: true
};

const devConfig = {
  apiKey: "AIzaSyDSc11PWwNee7Ss-sb9VKmS13dUO8x8xbg",
  authDomain: "docket-dev-237ce.firebaseapp.com",
  databaseURL: "https://docket-dev-237ce.firebaseio.com",
  projectId: "docket-dev-237ce",
  storageBucket: "docket-dev-237ce.appspot.com",
  messagingSenderId: "803844309135",
  timestampsInSnapshots: true
  
};

const config = window.location.hostname === 'forms.yourdocket.com'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  var defaultApp = firebase.initializeApp(config); 
  window.docketConfig = defaultApp.options.authDomain == prodConfig.authDomain ? "prod" : "dev";
}



const db = firebase.database();
const storage = firebase.storage();
const firestore = firebase.firestore();
const settings = {};
firestore.settings(settings);

export {
  db,
  storage,
  firestore,
  firebase
};

