
import React from 'react';
import PropTypes from 'prop-types';
let $ = window.$
class NameForm extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        let fields = {}

        fields.firstName = 
        {
            identifier: 'firstName',
            rules: [
                {
                    type   : 'empty',
                    prompt : 'Please enter your first name'
                }
            ]
        }

        fields.lastName = 
        {
            identifier: 'lastName',
            rules: [
                {
                    type   : 'empty',
                    prompt : 'Please enter your last name'
                }
            ]
        }
        if(!this.props.personalIsRequired){
            delete fields.firstName
            delete fields.lastName
        }
            
        fields.businessName = 
        {
            identifier: 'businessName',
            rules: [
                {
                    type   : 'empty',
                    prompt : 'Please enter the business name'
                }
            ]
        }
        if(!this.props.businessIsRequired){
            delete fields.businessName
        }

        this.nameChanged();
    }

    nameChanged(){
        let valid = this.validateForm();

        let name = undefined
        if(!this.props.businessOnly){
            name = {first: this.refs.firstName.value, last: this.refs.lastName.value}
        }
        let businessName = undefined
        if(this.refs.businessName !== undefined && this.refs.businessName.value !== undefined && this.refs.businessName.value !== ''){
            businessName = this.refs.businessName.value
        }
        
        if(this.props.nameChanged !== undefined){
            this.props.nameChanged(valid, name, businessName)
        }
    }

    validateForm(){
        // $('.nameForm').form('validate form')

        // let valid = false
        // if($('.nameForm').form('is valid')){
        //     valid = true
        // }

        let valid = false

        let personalValid = false
        if(this.refs.firstName !== undefined && this.refs.firstName.value !== undefined && this.refs.firstName.value !== '' &&
            this.refs.lastName !== undefined && this.refs.lastName.value !== undefined && this.refs.lastName.value !== ''){
            personalValid = true
        }

        let businessValid = false
        if(this.refs.businessName !== undefined && this.refs.businessName.value !== undefined && this.refs.businessName.value !== ''){
            businessValid = true
        }

        if(personalValid && this.props.oneIsRequired){
            valid = true
        }else if(businessValid && this.props.oneIsRequired){
            valid = true
        }else if(personalValid && businessValid){
            valid = true
        }else if(this.props.oneIsRequired === undefined || !this.props.oneIsRequired){
            if(personalValid || businessValid){
                valid = true
            }
        }

        return valid
    }

    getFirstNameValue(){
        if(this.props.entity !== undefined && this.props.entity.name !== undefined && this.props.entity.name.first !== undefined){
            return this.props.entity.name.first
        }else{
            return ''
        }
    }

    getLastNameValue(){
        if(this.props.entity !== undefined && this.props.entity.name !== undefined && this.props.entity.name.last !== undefined){
            return this.props.entity.name.last
        }else{
            return ''
        }
    }

    getBusinessName(){
        if(this.props.entity !== undefined && this.props.entity.businessName !== undefined){
            return this.props.entity.businessName
        }else{
            return ''
        }
    }

  render() {
    let personalNameLabel = <label style={{fontFamily: 'Roboto', fontWeight: '500'}}>Name</label>
    let businessNameLabel = <label style={{fontFamily: 'Roboto', fontWeight: '500'}}>Company</label>

    if(!this.props.showLabels){
        personalNameLabel = undefined
        businessNameLabel = undefined
    }

      let personalName = 
        <div className="field">
            {personalNameLabel}
            <div className="two fields">
                <div className="field">
                    <input ref='firstName' type="text" name="firstName" placeholder="First Name" 
                            spellCheck="false"
                            value={this.getFirstNameValue()} onChange={() => {this.nameChanged()}}/>
                </div>
                <div className="field">
                    <input ref='lastName' type="text" name="lastName" placeholder="Last Name" 
                            spellCheck="false"
                            value={this.getLastNameValue()} onChange={() => {this.nameChanged()}}/>
                </div>
            </div>
        </div>
    if(this.props.businessOnly){
        personalName = undefined
    }

    let businessName = 
        <div className="field">
            {businessNameLabel}
            <input ref='businessName' type="text" name="businessName" placeholder="Business Name" 
                        spellCheck="false"
                        value={this.getBusinessName()} onChange={() => {this.nameChanged()}}/>
        </div>
    
    if(this.props.personalOnly){
        businessName = undefined
    }

    return (
        <div className="nameForm" style={{fontSize: this.props.fontSize, margin: '1em 0em'}}>
            {personalName}
            {businessName}
        </div>
    );
  }
 
}

NameForm.propTypes = {
    entity: PropTypes.object,
    businessOnly: PropTypes.bool,
    businessIsRequired: PropTypes.bool,
    personalIsRequired: PropTypes.bool,
    oneIsRequired: PropTypes.bool,
    personalOnly: PropTypes.bool,
    nameChanged: PropTypes.func,
    showLabels: PropTypes.bool,
    fontSize: PropTypes.number,
}

export default NameForm
