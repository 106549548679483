
import React from 'react';
import PropTypes from 'prop-types';

class EditableAttachments extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  
    constructor(props) {
        super(props);
    }

    deleteAttachment(attachmentToBeDeleted){
        let newAttachmentsArray = []

        for(let i = 0; i < this.props.attachments.length; i++){
            let attachment = this.props.attachments[i]
            if(attachment.preview !== attachmentToBeDeleted.preview){
                newAttachmentsArray.push(attachment)
            }
        }

        this.props.attachmentsChanged(newAttachmentsArray)
    }

    imagesSelected(attachments, index){
        let tempIndex = index
        let imageURLs = []
        for(let key in attachments){
            let keyIndex = Number(key)
            let attachment = attachments[key]  
            if(attachment.type.startsWith('image')){
                if(attachment.src !== undefined){
                    imageURLs.push(attachment.src)
                }else if(attachment.preview !== undefined){
                    imageURLs.push(attachment.preview)
                }
            }else if(keyIndex < index){
                tempIndex--
            }
        }
        this.props.imagesSelected(imageURLs, tempIndex)
    }

    renderAttachments(){
        let atchs = []

        if(typeof this.props.attachments !== 'undefined'){
            for(let i = 0; i < this.props.attachments.length; i++){
                let index = i
                let attachment = this.props.attachments[i]
                if(typeof attachment !== "undefined" && typeof attachment.name !== "undefined" && attachment.name !== ""){
                    let leftSide = <i className="file outline icon"/>
                    if(attachment.name.endsWith('.png') || attachment.name.endsWith('.jpg') || attachment.name.endsWith('.gif')){
                        leftSide = <img src={attachment.preview}/>
                    }else if(attachment.name.endsWith('.xlsx') || attachment.name.endsWith('.xls')){
                        leftSide = <i className="green file excel outline icon"/>
                    }else if(attachment.name.endsWith('.docx') || attachment.name.endsWith('.doc')){
                        leftSide = <i className="blue file word outline icon"/>
                    }else if(attachment.name.endsWith('.pdf')){
                        leftSide = <i className="red file pdf outline icon"/>
                    }
                    atchs.push(
                        <div key={attachment.preview} className="ui image label" style={{marginBottom: '.5em'}}>
                            <span style={{cursor: 'pointer'}} onClick={() => {this.imagesSelected(this.props.attachments, index)}}>
                                {leftSide}
                                {attachment.name}
                            </span>
                            <i className="delete icon" onClick={() => {this.deleteAttachment(attachment)}}></i>
                        </div>
                    )
                }
            }
        }

        return atchs
    }

  render() {
    let attachments = <div/>
    if(typeof this.props.attachments !== 'undefined' && this.props.attachments.length > 0){
        attachments =
            <div className="ui labels" style={{paddingTop: '1em'}}>
                {this.renderAttachments()}
            </div>
    }
    
    return (
        attachments
    );
  }
 
}

EditableAttachments.propTypes = {
    attachments: PropTypes.array,
    attachmentsChanged: PropTypes.func,
}

export default EditableAttachments
